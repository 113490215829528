import React, { useMemo, useCallback } from 'react';
import useFetch from './hooks/useFetch';
import ArrowRightAlt from '@mui/icons-material/ArrowRightAlt';
import styled from 'styled-components';
import CopyAll from '@mui/icons-material/CopyAll';
import { bytesToSize } from './utils';
import useQuery, { useSetSearchQuery } from './useQuery';
import Filter from './ClusterSumamry/Filter';
import RenderPods from './ClusterSumamry/RenderPods';
import RenderStorage from './ClusterSumamry/RenderStorage';
import TokenLoginForm from './TokenLoginForm';
import { SYSTEM_NS } from './ClusterSumamry/helperfunctions'

const Divpadded = styled.div`
  padding: 20px;
`;

const TableWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ nodecount }) => [...Array(nodecount).keys()].map(() => '1fr').join(" ")};
  max-width: 100%;
`;

const TableDiv = styled.div`
  margin: 2px;
  border-right: 1px solid #ebebeb;
  padding: 2px;
  // overflow: hidden;
  > div.pods {
    font-size: 18px;
    margin: 0 0 2px 0;
    opacity: 0.75;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
`;

const FixedTitles = styled.div`
  background-color: #fff;
  position: sticky;
  // top: 30px;
  opacity: 1 !important;
  width: 100%;
`;


const InputStyled = styled.input`
  border: 1px solid #dadada;
  border-radius: 3px;
  height: 24px;
  font-size: 12px;
  padding: 0 8px;
`

function SearchQueryFilterInput({ name, placeholder, width }) {
  const { setSearchQuery } = useSetSearchQuery();
  const currentquery =
    useQuery();

  return (
    <InputStyled
      placeholder={placeholder}
      onChange={(e) => setSearchQuery({ [name]: e.target.value })}
      value={currentquery[name]}
      style={{ width: width || '60px' }}
    />
  )
}


function RenderCheckbox({ searchqueryname, label }) {
  const currentquery =
    useQuery();
  const { setSearchQuery } = useSetSearchQuery();
  const checked = useMemo(() => {
    const curr = currentquery[searchqueryname]
    // console.log(curr, currentquery, searchqueryname)
    return curr === 'true'
  }, [currentquery, searchqueryname])

  return <div>
    <input
      type="checkbox"
      id={searchqueryname}
      name={searchqueryname}
      checked={checked}
      onChange={(e) => setSearchQuery({ [searchqueryname]: e.target.checked })}
    />
    <label htmlFor={searchqueryname}>{label}</label>
  </div>

}

// const Hr = styled.div`
//   margin: 0 5px;
//   width: 1px;
//   background: #dadada;
//   height: 22px;
// `;

const MARKRED = [// '296', '297', '298'
]

function RenderOneServerInfo({
  label,
  status,
  serverinfo,
  maxLinesOfDu,
}) {
  const { pods, kernelVersion, kubeletVersion, du } = serverinfo || {};
  const overallstorage = useMemo(
    () => (du && du.length ? bytesToSize(du[du.length - 1].size) : ''),
    [du]
  );
  const style = useMemo(() => MARKRED.includes(label) ? { color: 'RED' } : null, [label])

  const podcounts = useMemo(() => {
    let podcountsystem = 0, podcountrest = 0
    pods.forEach(pod => {
      if (SYSTEM_NS.includes(pod.ns)) {
        podcountsystem += 1
      } else {
        podcountrest += 1
      }
    })
    return {
      podcountsystem,
      podcountrest
    }
  }, [pods])

  if (!serverinfo) {
    return <TableDiv>Server not found in cluster</TableDiv>
  }

  return (
    <TableDiv>
      <FixedTitles className="pods">
        <span style={style}>{label}</span> <ArrowRightAlt /> {pods ? pods.length : 0}pods (
        {podcounts.podcountrest}+{podcounts.podcountsystem}system), {overallstorage}
      </FixedTitles>
      <div>
        {status}
        {kernelVersion} {kubeletVersion}
        {du ? <RenderStorage du={du} maxLinesOfDu={maxLinesOfDu} /> : null}
        {pods ? <RenderPods pods={pods} /> : <div>Keine pods</div>}
      </div>
    </TableDiv>
  );
}

// function sortByPodcount(l, r) {
//   if (l.pods.length === r.pods.length) {
//     return 0;
//   }
//   return l.pods.length < r.pods.length ? 1 : -1;
// }


const DivBottomline = styled.div`border-bottom: 1px solid black`

function StatusIcon({ namespaceMatchNewCluster }) {
  if (namespaceMatchNewCluster === undefined) {
    return null
  }
  if (namespaceMatchNewCluster && namespaceMatchNewCluster.length) {
    return <span style={{ fontWeight: 'bold', color: 'green' }} title="Deployed in k3s">☑</span>
  }
  return <span style={{ fontWeight: 'bold', color: 'red', marginRight: '4px' }} title="Deployed in k3s">x</span>
}

function RenderOneNS({
  namespace,
  pods,
  // volumes,
  index,
  pvcs,
  deployments,
  showSummaryDeployments,
  showSummaryPvcs,
  showTodos,
  namespaceMatchNewCluster
}) {
  // console.log(pods)
  // const deploymentsstyle = useMemo(() => {
  //   if (pods.length < deployments.length) {
  //     return { background: 'orange' }
  //   }
  //   return null
  // }, [deployments, pods])

  const hasNodeaffinities = deployments.filter(de => de.nodeAffinity).length
  const scaledDownDeployments = pods.length < deployments.length
  const hasLocalstorage = pvcs && pvcs.filter(pvc => pvc.spec__storage_class_name === 'local-storage').length
  const hasTodos = hasNodeaffinities || scaledDownDeployments || hasLocalstorage

  if (showTodos && !hasTodos) {
    return null
  }

  return <React.Fragment>
    <DivBottomline><StatusIcon /> {index + 1} {namespace}</DivBottomline>
    <DivBottomline>
      {deployments.length} deployments {hasNodeaffinities ? <span style={{ background: 'red' }}>nodeAffinity!</span> : null} {scaledDownDeployments ? <span style={{ background: 'orange' }}>stopped pods!</span> : null} {hasLocalstorage ? <span style={{ background: 'darkorange' }}>local-storage</span> : null}
      {showSummaryDeployments ? <><br />
        {deployments.map(dep => <span style={{ border: '1px solid green', background: 'lightgreen', marginRight: '6px' }}>{dep.name} {dep.status__replicas}</span>)}</> : null}
    </DivBottomline>
    <DivBottomline>{pods.length} pods</DivBottomline>
    <DivBottomline>
      {pvcs.length ? <>{pvcs.length} pvcs
        {showSummaryPvcs ? <><br />
          {pvcs.map(pvc => <div>{pvc.spec__storage_class_name} {pvc.status__capacity__storage} {pvc.name}</div>)}</> : null}
      </> : null}
    </DivBottomline>
    {/* <div>{volumes.length}</div> */}
  </React.Fragment>
}

const EXCLUDE_NS = [
  'rook-ceph',
  'prometheus-agent',
  'nextcloud',
  'tralios-kube-state-metrics',
  'tralios-traefik',
  'metallb-system',
  'kube-fledged',
  'kube-node-lease',
  'kube-public',
  'kube-system',
  'kubernetes-dashboard',
  'metallb-system',
  'default',
  'gitlab',
]

function ShowSummary({
  data,
  showSummaryDeployments,
  showSummaryPvcs,
  showTodos,
  showDomains,
}) {
  // const allnsbypodcount = useMemo(() => {
  //   const res = {}
  //   Object.keys(nodesdata).forEach(nodename => {
  //     const val = nodesdata[nodename]
  //     val.pods.forEach(pod => {
  //       if (!res[pod.ns]) {
  //         res[pod.ns] = { name: pod.ns, pods: [], volumes: [] }
  //       }
  //       res[pod.ns].pods.push(pod)
  //       pod.volumes.forEach(vol => {
  //         res[pod.ns].volumes.push({ ...vol, pod: pod.name })
  //       })
  //     })
  //     // console.log('val', val)
  //   })
  //   const resarray = Object.keys(res).map(ns => res[ns])

  //   resarray.sort(sortByPodcount)

  //   return resarray.filter(ns => ns.pods.length > 1)
  // }, [nodesdata])

  const {
    namespaces,
    deployments,
    pvcs,
    domains = {},
  } = data

  const overallcount = useMemo(() => {
    let cnt = 0
    Object.keys(namespaces).forEach(ns => {
      cnt += namespaces[ns].pods.length
    })
    return cnt
  }, [namespaces])

  // console.log('namespacesdata', namespaces)

  const namespacecount = useMemo(() => {
    let cnt = 0
    Object.keys(namespaces).filter(ns => namespaces[ns].clustername !== "k3s").forEach(ns => {
      cnt += 1
    })
    return cnt
  }, [namespaces])

  const copyToClipboard = useCallback(() => {
    const headers = [
      'NAMESPACE',
      'NODEAFFINITIES',
      'HASLOCALSTORAGE',
      'pvccount'
    ]
    const out = [
      headers.join('\t')
    ]

    Object.keys(namespaces).filter(ns => !EXCLUDE_NS.includes(ns)).forEach(ns => {
      const nsdepl = deployments.filter(dep => dep.namespace === ns)
      const nspvcs = pvcs.filter(pvc => pvc.namespace === ns)
      const dataentry = [
        ns,
        nspvcs && nspvcs.filter(pvc => pvc.spec__storage_class_name === 'local-storage').length,
        nsdepl.filter(de => de.nodeAffinity).length,
        nspvcs && nspvcs.length,
      ]
      out.push(dataentry.join('\t'))
    })
    navigator.clipboard.writeText(out.join("\n"));
  }, [deployments, namespaces, pvcs])

  // const orderedDomains = useMemo(() => {
  // }, [])

  return <>
    {showDomains ? <><h1>Domains and resolved IP-Address and namespace</h1>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '380px 200px 250px 1fr',
          gridGap: '0 1rem',
          border: '1px solid gray',
          // marginBottom: '8px',
        }}
      >
        {Object.keys(domains).map((domain, index) => (
          <React.Fragment key={index}>
            <DivBottomline>{index + 1} {domain}</DivBottomline>
            <DivBottomline>
              {domains[domain].ip !== "5.253.0.148" ? <span style={{ background: 'red' }}>{domains[domain].ip}</span> : domains[domain].ip}
            </DivBottomline>
            <DivBottomline>{domains[domain].namespace}</DivBottomline>
            <DivBottomline>
            </DivBottomline>
          </React.Fragment>
        ))}
      </div>
    </> : null}
    <b>Namespaces {namespacecount}, Pod count summary: {overallcount}</b> <button type="button" onClick={copyToClipboard}><CopyAll /></button>
    <br />
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '330px 420px 150px 1fr',
        gridGap: '0 1rem',
        border: '1px solid gray',
        // marginBottom: '8px',
      }}
    >
      {Object.keys(namespaces).filter(ns => !EXCLUDE_NS.includes(ns)).map((ns, index) => (
        <RenderOneNS
          namespace={ns}
          pods={namespaces[ns].pods}
          volumes={namespaces[ns].volumes}
          key={index}
          index={index}
          pvcs={pvcs.filter(pvc => pvc.namespace === ns)}
          deployments={deployments.filter(dep => dep.namespace === ns)}
          showSummaryDeployments={showSummaryDeployments}
          showSummaryPvcs={showSummaryPvcs}
          showTodos={showTodos}
        />
      ))}
    </div>
    <br />
    Other namespaces:
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '330px 420px 150px 1fr',
        gridGap: '0 1rem',
        border: '1px solid gray',
        // marginBottom: '8px',
      }}
    >
      {Object.keys(namespaces).filter(ns => EXCLUDE_NS.includes(ns)).map((ns, index) => (
        <RenderOneNS
          namespace={ns}
          pods={namespaces[ns].pods}
          volumes={namespaces[ns].volumes}
          key={index}
          index={index}
          pvcs={pvcs.filter(pvc => pvc.namespace === ns)}
          deployments={deployments.filter(dep => dep.namespace === ns)}
          showSummaryDeployments={showSummaryDeployments}
          showSummaryPvcs={showSummaryPvcs}
          showTodos={showTodos}
        />
      ))}
    </div>
  </>
}

export default function ClusterSummaryComponent() {
  const {
    filterVal,
    exclude,
    prioFilter,
    showServersWithPods,
    showNamespaces,
    showSummaryDeployments,
    showSummaryPvcs,
    showTodos,
    showDomains,
  } = useQuery();

  const { data, error } = useFetch('/api/clustersummary');

  const reload = useCallback(() => {
    const token = window.location.hash.replace('#token=', '');
    window
      .fetch("/api/scheduleclustersummaryreload", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${token}`,
        },
      })
    window.alert("The rebuild will take > 5 minutes, please stay patient.")
  }, []);

  const nodesdata = useMemo(() => {
    if (!data) {
      return {};
    }
    const allnodesdata = {}

    const { pvcs } = data

    Object.keys(data.nodes).forEach(nodename => {
      allnodesdata[nodename] = data.nodes[nodename]
      allnodesdata[nodename].pods = allnodesdata[nodename].pods.map(pod => {
        if (!pod.volumes) {
          return pod
        }
        pod.volumes = pod.volumes.map(vol => {
          // console.log('v', vol, pvcs)
          const pvcmatch = pvcs.find(pvc => pvc.namespace === pod.ns && pvc.name === vol.claim_name) || {}
          const { name, namespace, ...rest } = pvcmatch
          return { ...vol, ...rest }
        })
        console.log(pod.volumes)
        return pod
      })
    });

    // pvcs={pvcs.filter(pvc => pvc.namespace === ns)}

    console.log('111', allnodesdata, data)

    if (!filterVal && !exclude && !prioFilter) {
      return allnodesdata;
    }
    const newdat = {};
    if (filterVal) {
      Object.keys(allnodesdata).forEach((node) => {
        const filterednodepods = allnodesdata[node]?.pods.filter((pod) => {
          if (pod.ns.includes(filterVal)) {
            return true;
          }
          if (pod.name.includes(filterVal)) {
            return true;
          }
          return false;
        });
        newdat[node] = { ...allnodesdata[node], pods: filterednodepods };
      });
    }

    if (exclude) {
      const clonnedData =
        Object.keys(newdat).length > 0
          ? JSON.parse(JSON.stringify(newdat))
          : JSON.parse(JSON.stringify(allnodesdata));

      Object.keys(clonnedData).forEach((node) => {
        // console.log({clonnedData});
        const filterednodepods = clonnedData[node]?.pods.filter((pod) => {
          if (pod.ns.includes(exclude)) {
            return false;
          }
          if (pod.name.includes(exclude)) {
            return false;
          }
          return true;
        });
        newdat[node] = { ...clonnedData[node], pods: filterednodepods };
      });
    }

    if (prioFilter) {
      const clonnedData =
        Object.keys(newdat).length > 0
          ? JSON.parse(JSON.stringify(newdat))
          : JSON.parse(JSON.stringify(allnodesdata));
      const direction = prioFilter[0];
      const number = parseInt(prioFilter.substring(1));

      if (direction !== '>' && direction !== '<' && !Number.isInteger(number)) {
        return clonnedData;
      }

      Object.keys(clonnedData).forEach((node) => {
        const filterednodepods = clonnedData[node]?.pods.filter((pod) => {
          if (direction === '>') {
            return pod.prio > number;
          }

          return pod.prio < number;
        });
        newdat[node] = { ...clonnedData[node], pods: filterednodepods };
      });
    }

    return newdat;
  }, [data, exclude, filterVal, prioFilter]);

  const maxLinesOfDu = useMemo(() => {
    let max = 0;
    if (!nodesdata) {
      return 0;
    }
    Object.keys(nodesdata).forEach((serv) => {
      if (nodesdata[serv]?.du?.length > max) {
        max = nodesdata[serv]?.du?.length;
      }
    });
    return max;
  }, [nodesdata]);

  if (error) {
    return <TokenLoginForm />;
  }
  if (!data) {
    return <Divpadded>Loading...</Divpadded>;
  }

  return (
    <>
      <Filter data={data} reload={reload} >

      </Filter>

      <div style={{ margin: '40px 10px' }}>
        <h2><RenderCheckbox searchqueryname="showNamespaces" label="show namespaces" /></h2>
        {showNamespaces ? <>
          <RenderCheckbox searchqueryname="showSummaryDeployments" label="m: show deployments" />
          <RenderCheckbox searchqueryname="showSummaryPvcs" label="n: show pvcs" />
          <RenderCheckbox searchqueryname="showTodos" label="show Todos only" />
          <RenderCheckbox searchqueryname="showDomains" label="show Domains" /></> : null}
        {showNamespaces && data && data.namespaces ? <ShowSummary
          data={data}
          showSummaryDeployments={showSummaryDeployments}
          showSummaryPvcs={showSummaryPvcs}
          showTodos={showTodos}
          showDomains={showDomains}
        /> : null}

        <h2><RenderCheckbox searchqueryname="showServersWithPods" label="show servers with pods" /></h2>
        {showServersWithPods ? <>
          <SearchQueryFilterInput
            placeholder="Filter value..."
            name="filterVal" width='60px'
          />
          <SearchQueryFilterInput
            placeholder="Exclude pods..."
            name="exclude" width='60px'
          />     <SearchQueryFilterInput
            placeholder="Prio"
            name="prioFilter" width='30px'
          />
          <RenderCheckbox searchqueryname="showSysPods" label="show sys pods" />
          <RenderCheckbox searchqueryname="markRestartedPods" label="mark restarted pods" />
          <RenderCheckbox searchqueryname="showStopped" label="mark stopped" />
          <RenderCheckbox searchqueryname="showDateStarted" label="show date_started" />
          <RenderCheckbox searchqueryname="showVolumes" label="show volumes" />
        </> : null}
        {showServersWithPods ? <TableWrapper nodecount={Object.keys(nodesdata).length}>
          {Object.keys(nodesdata).map((serv) => (
            <RenderOneServerInfo
              key={serv}
              label={serv}
              serverinfo={nodesdata[serv]}
              maxLinesOfDu={maxLinesOfDu}
            />
          ))}
        </TableWrapper> : null}
      </div>
    </>
  );
}
