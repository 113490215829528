import React, { useState, useCallback, useEffect } from 'react';

export function ToggleFilterCheckbox({ name, label, kind, setFilterOnKind }) {
  const [checked, setChecked] = useState(false);

  const handleClick = useCallback(() => {
    const newChecked = !checked;
    setFilterOnKind(kind, newChecked);
    setChecked(newChecked);
  }, [checked, setChecked, setFilterOnKind, kind]);

  return (
    <div>
      <input
        type="checkbox"
        id={name}
        name={name}
        checked={checked}
        readOnly
        onClick={handleClick}
      />
      <label htmlFor={name}>{label}</label>
    </div>
  );
}

// function sortByName(l, r) {
//   if (l.name === r.name) {
//     return 0;
//   }
//   return l.name > r.name ? 1 : -1;
// }

function sortByDomain(l, r) {
  if (l.kind !== 'inopai-production') {
    return 1;
  }
  if (r.kind !== 'inopai-production') {
    return -1;
  }

  if (l.installationdomain === r.installationdomain) {
    return 0;
  }
  return l.installationdomain > r.installationdomain ? 1 : -1;
}

export default function useFilteredAndOrderedDeploymentList(deploymentList) {
  const [filterConfig, setFilterConfig] = useState({
    'inopai-production': true,
    deployment: false,
    'inopai-dev': true,
  });

  const [orderConfig, setOrderConfig] = useState({ orderFunction: sortByDomain });

  const [filteredDeploymentList, setFilteredDeploymentList] = useState(deploymentList);

  const activateFilterOnKind = useCallback(
    (key) => {
      const newConfig = { ...filterConfig, [key]: true };
      setFilterConfig(newConfig);
    },
    [filterConfig]
  );

  const deactivateFilterOnKind = useCallback(
    (key) => {
      const newConfig = { ...filterConfig, [key]: false };
      setFilterConfig(newConfig);
    },
    [filterConfig]
  );

  const setFilterOnKind = useCallback(
    (key, state) => {
      const newConfig = { ...filterConfig, [key]: state };
      setFilterConfig(newConfig);
    },
    [filterConfig]
  );

  const setOrderFunction = useCallback((fun) => {
    setOrderConfig({ orderFunction: fun });
  }, []);

  useEffect(() => {
    const { orderFunction } = orderConfig;

    const newDeploymentList = deploymentList.filter((v) => {
      const { kind } = v;

      if (!(kind in filterConfig)) {
        // if kind is not in config, then keep the value
        return true;
      }
      return filterConfig[kind];
    });

    newDeploymentList.sort(orderFunction);

    setFilteredDeploymentList(newDeploymentList);
  }, [deploymentList, filterConfig, orderConfig]);

  return {
    filteredDeploymentList,
    activateFilterOnKind,
    deactivateFilterOnKind,
    setFilterOnKind,
    setOrderFunction,
  };
}
