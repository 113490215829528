import React, { useMemo, useState, useCallback } from 'react';
import styled from 'styled-components';
import Home from '@mui/icons-material/Home';
import Select from 'react-select';

import CheckInstallationStatus from './CheckInstallationStatus';
import useProjectProps from './hooks/useProjectProps';
import ProjectHeaderIcons from './components/ProjectHeaderIcons';
import ProjectDetailChart from './ProjectDetailChart';

import { getActionlogQueryParamsAgoModifier } from './utils';
import { useInopaiActionlogs } from './hooks/useInopaiActionlogs';
import QueryLogsRenderSparkline from './components/QueryLogsRenderSparkline';

import useFetch from './hooks/useFetch';
import { useParams } from 'react-router-dom';
import LinkWithToken from './components/LinkWithToken';
import TokenLoginForm from './TokenLoginForm';
import CollapseChildren from './components/CollapseChildren'

//##############################################
//################### Style ####################
//##############################################
const Holder = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 15px;
  word-break: break-word;
`;

const Divpadded = styled.div`
  padding: 20px;
`;

const TinyChartRowHolder = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const InfoHolder = styled.div`
  font-size: 14px;
  padding: 10px 15px;
  background: #f1f1f1;
  border-radius: 5px;
  margin: 10px 0;
  border: none;
`;
const ErrorMessage = styled.div`
  color: #fff;
  background: #be2117;
  padding: 15px;
  margin: 30px 15px 15px 15px;
  font-size: 16px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const IconsHolder = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

const HomeLinkHolder = styled(LinkWithToken)`
    padding 0 4px;
    color: #c0c0c0;
    :hover {
        color: #2a94cf;
    }
`;

const ChartStatisticsHolder = styled.div`
  font-size: 15px;
  padding: 15px 20px;
  border-radius: 3px;
  border: 1px solid #c0c0c0;
`;

const ChartStatisticsContentRowHolder = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 10px 0;
`;

const ChartStatisticsContentHolder = styled.div`
  width: 100px;
  font-weight: bold;
  margin-left: 40px;
`;

const ReactSelectElement = styled(Select)`
  width: 100%;
  max-width: 250px;
`;

const ReactTinySelectElement = styled(Select)`
  width: 100%;
  max-width: 100px;
`;

const ChartDateHolder = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  // grid-auto-flow:row;
  align-items: center;
  grid-row-gap: 10px;
`;

const ChartDateContentHolder = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  & > span {
    margin: 0 10px;
  }
`;

const ShowChartOptionsButton = styled.button`
  color: #2a94cf;
  border-radius: 3px;
  width: 150px;
  background-color: #fff;
  padding: 4px 8px;
  border: 1px solid #2a94cf;
  font-weight: 600;
  margin: 10px 15px;
`;

const ChartNotificationLabel = styled.div`
  color: #be2117;
  padding-left: 15px;
`;

//##############################################
//############ Select - Options ################
//##############################################

const chartTypeOptions = [
  { value: 'BarChart', label: 'Bar Chart' },
  { value: 'LineChart', label: 'Line Chart' },
];

const weekFilterOptions = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
];

const dayFilterOptions = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '5', label: '5' },
  { value: '7', label: '7' },
  { value: '10', label: '10' },
  { value: '14', label: '14' },
];

const hourFilterOptions = [
  { value: '1', label: '1' },
  { value: '3', label: '3' },
  { value: '6', label: '6' },
  { value: '12', label: '12' },
  { value: '30', label: '30' },
  { value: '60', label: '60' },
  { value: '120', label: '120' },
];

const groupOptions = [
  { value: 'day', label: 'days' },
  { value: 'hour', label: 'hours' },
  { value: 'week', label: 'weeks' },
];

function calculateValuesSum(obj) {
  let res = 0;
  Object.keys(obj).forEach((logtype) => {
    const val = obj[logtype];
    res += val;
  });
  return res;
}

function useStickyState(defaultValue, key) {
  const [value, setValue] = React.useState(() => {
    const stickyValue = window.localStorage.getItem(key);
    return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
  });
  React.useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);
  return [value, setValue];
}

function convertKnownActionTypesAsSelectOptions(actionTypes) {
  var options = [];
  options.push({ value: 'all', label: 'Alle' }); // default option
  actionTypes.forEach((labelkey) => {
    options.push({ value: labelkey, label: labelkey });
  });
  return options;
}


function dateXDaysAgo(x) {
  const currentTime = new Date()
  currentTime.setDate(currentTime.getDate() - x);
  return currentTime
}


function GeneratedDetailedContent({ project }) {
  const { kubectllink, projUrl } = useProjectProps(project);

  console.log('project', project);

  const [showChartOptions, setShowChartOptions] = useState(false);

  const toggleShowChartOptions = useCallback(() => {
    setShowChartOptions(!showChartOptions);
  }, [showChartOptions]);

  const maxDatePicker = new Date().toISOString()
  const minDatePicker = dateXDaysAgo(31).toISOString()

  const [startDate, setStartDate] = useState(
    dateXDaysAgo(31).toISOString()
  );
  const [endDate, setEndDate] = useState(new Date().toISOString());
  const [dateRadioOption, setDateRadioOption] = useStickyState('last', 'dateRadioOption');
  const [dateSelectOptionValue, setDateSelectOptionValue] = useStickyState(
    weekFilterOptions[1],
    'dateSelectOption'
  );
  const [groupOptionValue, setGroupOptionValue] = useStickyState(
    groupOptions[0],
    'groupOptionValue'
  );
  const [chartType, setChartType] = useStickyState(chartTypeOptions[0], 'chartType');
  const [subjects, setSubjects] = useStickyState(
    [{ value: 'all', label: 'all Subject' }],
    'subjects'
  );

  const actionlogparams = useMemo(() => {
    if (dateRadioOption === 'last') {
      return getActionlogQueryParamsAgoModifier(
        dateSelectOptionValue.value,
        groupOptionValue.value
      );
    } else if (dateRadioOption === 'range') {
      const startdate = startDate;
      const enddate = endDate;
      return { startdate, enddate };
    } else {
      return getActionlogQueryParamsAgoModifier(4, 'weeks');
    }
  }, [dateSelectOptionValue, groupOptionValue, dateRadioOption, startDate, endDate]);

  console.log('actionlogparams', actionlogparams)

  const actionlogsresp = useInopaiActionlogs(
    project.kind,
    projUrl,
    groupOptionValue.value,
    actionlogparams.startdate,
    actionlogparams.enddate
  );

  const dataResult = useMemo(() => {
    if (!actionlogsresp) {
      return null;
    }
    if (!Object.keys(actionlogsresp)) {
      return null;
    }

    const data = [];
    Object.keys(actionlogsresp).forEach(function (date) {
      const val = actionlogsresp[date];
      const dataElement = {
        date,
        alldata: val,
        count: calculateValuesSum(val),
        ...val,
      };
      data.push(dataElement);
    });

    return data;
  }, [actionlogsresp]);

  const knownActionTypes = useMemo(() => {
    if (!actionlogsresp) {
      return [];
    }
    if (!Object.keys(actionlogsresp)) {
      return [];
    }

    const res = [];
    Object.keys(actionlogsresp).forEach(function (date) {
      const val = actionlogsresp[date];
      Object.keys(val).forEach((actiontype) => {
        if (!res.includes(actiontype)) {
          res.push(actiontype);
        }
      });
    });
    return res;
  }, [actionlogsresp]);

  return (
    <Holder>
      <IconsHolder>
        <HomeLinkHolder pathname="/">
          <Home />
        </HomeLinkHolder>
        <ProjectHeaderIcons kubectllink={kubectllink} projUrl={projUrl} project={project} />
      </IconsHolder>

      {project.kind.includes('inopai') ? (
        <QueryLogsRenderSparkline actionlogsresp={actionlogsresp} />
      ) : null}

      {project.kind.includes('inopai') ? (
        <>
          <ShowChartOptionsButton type="button" onClick={toggleShowChartOptions}>
            {showChartOptions ? 'Hide Chart-Options' : 'Show Chart-Options'}
          </ShowChartOptionsButton>

          {showChartOptions ? (
            <ChartStatisticsHolder>
              <ChartStatisticsContentRowHolder>
                <ChartStatisticsContentHolder>Chart Type:</ChartStatisticsContentHolder>
                <ReactSelectElement
                  options={chartTypeOptions}
                  defaultValue={chartType}
                  onChange={setChartType}
                />
                <ChartStatisticsContentHolder>Subject:</ChartStatisticsContentHolder>
                <ReactSelectElement
                  isMulti
                  closeMenuOnSelect={false}
                  options={convertKnownActionTypesAsSelectOptions(knownActionTypes)}
                  defaultValue={subjects}
                  onChange={setSubjects}
                />
              </ChartStatisticsContentRowHolder>
              <ChartStatisticsContentRowHolder style={{ alignItems: 'baseline' }}>
                <ChartStatisticsContentHolder>Date:</ChartStatisticsContentHolder>
                <ChartDateHolder>
                  <input
                    type="radio"
                    name="date"
                    value="actionlogparams"
                    checked={dateRadioOption === 'all'}
                    onChange={(e) => setDateRadioOption(e.target.value)}
                  />
                  <ChartDateContentHolder>
                    <span>Last 4 weeks</span>
                  </ChartDateContentHolder>
                  <input
                    type="radio"
                    name="date"
                    value="range"
                    checked={dateRadioOption === 'range'}
                    onChange={(e) => setDateRadioOption(e.target.value)}
                  />
                  <ChartDateContentHolder>
                    <span>from</span>
                    <input
                      type="date"
                      name="startDate"
                      value={startDate}
                      max={maxDatePicker}
                      min={minDatePicker}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                    <span>to</span>
                    <input
                      type="date"
                      name="endDate"
                      value={endDate}
                      max={maxDatePicker}
                      min={minDatePicker}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </ChartDateContentHolder>
                  <input
                    type="radio"
                    name="date"
                    value="last"
                    checked={dateRadioOption === 'last'}
                    onChange={(e) => setDateRadioOption(e.target.value)}
                  />
                  <ChartDateContentHolder>
                    <span>last</span>
                    {groupOptionValue.value === 'day' ? (
                      <ReactTinySelectElement
                        options={dayFilterOptions}
                        defaultValue={dateSelectOptionValue}
                        onChange={setDateSelectOptionValue}
                      />
                    ) : null}
                    {groupOptionValue.value === 'hour' ? (
                      <ReactTinySelectElement
                        options={hourFilterOptions}
                        defaultValue={dateSelectOptionValue}
                        onChange={setDateSelectOptionValue}
                      />
                    ) : null}
                    {groupOptionValue.value === 'week' ? (
                      <ReactTinySelectElement
                        options={weekFilterOptions}
                        defaultValue={dateSelectOptionValue}
                        onChange={setDateSelectOptionValue}
                      />
                    ) : null}
                    <ReactTinySelectElement
                      options={groupOptions}
                      defaultValue={groupOptionValue}
                      onChange={setGroupOptionValue}
                    />
                  </ChartDateContentHolder>
                </ChartDateHolder>
              </ChartStatisticsContentRowHolder>
            </ChartStatisticsHolder>
          ) : null}
          {subjects !== null ? (
            subjects.length === 1 && subjects[0].value === 'all' ? (
              <>
                <TinyChartRowHolder>
                  <ProjectDetailChart
                    label={'Overall activity'}
                    labelKey={'count'}
                    type={chartType.value}
                    color={'#8884d8'}
                    dataResult={dataResult}
                    isBigSize={true}
                  />
                </TinyChartRowHolder>
                <TinyChartRowHolder>
                  {knownActionTypes.map((labelkey) => (
                    <ProjectDetailChart
                      key={labelkey}
                      label={labelkey}
                      labelKey={labelkey}
                      color={'#ff5050'}
                      type={chartType.value}
                      dataResult={dataResult}
                    />
                  ))}
                </TinyChartRowHolder>
              </>
            ) : (
              <>
                {subjects.length === 1 ? (
                  <ProjectDetailChart
                    label={subjects[0].label}
                    labelKey={subjects[0].value}
                    color={'#8884d8'}
                    type={chartType.value}
                    dataResult={dataResult}
                    isBigSize={true}
                  />
                ) : (
                  <TinyChartRowHolder>
                    {subjects.map((subject) => {
                      if (subject.value !== 'all') {
                        return (
                          <ProjectDetailChart
                            label={subject.label}
                            labelKey={subject.value}
                            color={'#ff5050'}
                            type={chartType.value}
                            dataResult={dataResult}
                          />
                        );
                      } else {
                        return null;
                      }
                    })}
                  </TinyChartRowHolder>
                )}
              </>
            )
          ) : (
            <ChartNotificationLabel>
              To display a chart, please select subjects or actionTypes in chart options.
            </ChartNotificationLabel>
          )}
        </>
      ) : null}

      <PInfo project={project} projUrl={projUrl} />
    </Holder>
  );
}

function PInfo({ projUrl, project }) {

  const {
    deployvariablesjson,
    configurationenv,
    backend_cpu_limits,
    backend_logs_storage,
    backend_media_storage,
    elasticsearch_cpu_limits,
    elasticsearch_mem,
    elasticsearch_storage,
    last_activity_at,
    postgres_storage,
  } = project;

  return <CollapseChildren label="Show all settings">
    {deployvariablesjson ? (
      <InfoHolder>
        {Object.keys(deployvariablesjson).map((varname, index) => (
          <div key={index}>
            <b>{varname}:</b> {deployvariablesjson[varname]}
          </div>
        ))}
        <div>backend_cpu_limits: {backend_cpu_limits}</div>
        <div>backend_logs_storage: {backend_logs_storage}</div>
        <div>backend_media_storage: {backend_media_storage}</div>
        <div>elasticsearch_cpu_limits: {elasticsearch_cpu_limits}</div>
        <div>elasticsearch_mem: {elasticsearch_mem}</div>
        <div>elasticsearch_storage: {elasticsearch_storage}</div>
        <div>last_activity_at: {last_activity_at}</div>
        <div>postgres_storage: {postgres_storage}</div>
      </InfoHolder>
    ) : null}
    {configurationenv ? (
      <>
        <InfoHolder>
          {Object.keys(configurationenv).map((varname, index) => (
            <div key={index}>
              <b>{varname}:</b> {configurationenv[varname]}
            </div>
          ))}
        </InfoHolder>
      </>
    ) : null}
    {project.kind.includes('inopai') ? (
      <InfoHolder>
        <CheckInstallationStatus projUrl={projUrl} />
      </InfoHolder>
    ) : null}
  </CollapseChildren>
}

export default function ProjectDetail() {
  const params = useParams();
  const { data, error } = useFetch('/api/deployments');

  const { projectId } = params;

  const project = useMemo(
    () => data?.find((p) => p.projectid.toString() === projectId),
    [data, projectId]
  );

  if (error) {
    return <TokenLoginForm />;
  }

  if (!data) return <Divpadded>Loading...</Divpadded>;

  if (!project) {
    return (
      <ErrorMessage>
        No information found for this project id! Please search for correct project id!
      </ErrorMessage>
    );
  }

  return <GeneratedDetailedContent project={project} />;
}
