import { useCallback, useEffect, useState } from 'react';

export function useInopaiActionlogs(kind, projUrl, groupby = 'day', start_date, end_date) {
  const [response, responseSet] = useState();

  const logsToken = '67hC8xXKpCsL7zCXmvPcfc97';

  const doActionlogsCall = useCallback(async () => {
    responseSet({ status: 'loading' });

    try {
      const resp = await window.fetch(
        `${projUrl}/api/adminlog/?token=${logsToken}&groupby=${groupby}&start_date=${start_date}&end_date=${end_date}`
        //  {
        //   method: "POST",
        //   headers: { "Content-Type": "application/json" },
        //   body: JSON.stringify({ token: migrationToken }), }
      );
      const data = await resp.json();
      // console.log("await resp.json()", data);
      responseSet(data);
    } catch (err) {
      const msg = `Actionlogs errors: ${JSON.stringify(err)}`;
      console.log(msg);
      responseSet({ error: msg });
    }
  }, [end_date, groupby, projUrl, start_date]);

  useEffect(() => {
    if (kind.includes('inopai')) {
      doActionlogsCall();
    } else {
      responseSet({ error: 'unknown project kind' });
    }
  }, [doActionlogsCall, kind]);

  return response;
}
