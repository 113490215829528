import React from 'react';
import HelpOutline from '@mui/icons-material/HelpOutline';
import DoneOutline from '@mui/icons-material/DoneOutline';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import Warning from '@mui/icons-material/Warning';
import SignalWifiConnectedNoInternet4Icon from '@mui/icons-material/SignalWifiConnectedNoInternet4';
import styled from 'styled-components';

const HelpOutlineStyled = styled(HelpOutline)`
  color: #e68a00;
  :hover {
    color: #995c00;
  }
`;

const DoneOutlineStyled = styled(DoneOutline)`
  color: #66cc00;
  :hover {
    color: #408000;
  }
`;

const ErrorOutlineStyled = styled(ErrorOutline)`
  color: #dc143c;
  :hover {
    color: #b22222;
  }
`;

const WarningOutlineStyled = styled(Warning)`
  color: #e68a00;
  :hover {
    color: #c87a06;
  }
`;

export default function QuickStatusSummary({ status, messages }) {
  if (messages && messages.connection === '{}') {
    return <SignalWifiConnectedNoInternet4Icon />
  }
  // {/* { status === 'loading' ? null : tooltip  messages && Object.keys(messages).length > 0 ? <pre>{JSON.stringify(messages)}</pre> : null} */}
  if (status === 'unknown') {
    return <HelpOutlineStyled />;
  }
  if (status === 'loading') {
    return <>...</>;
  }
  if (status === 'ok') {
    return <DoneOutlineStyled />;
  }
  if (status === 'error') {
    return <ErrorOutlineStyled />;
  }
  if (status === 'warning') {
    return <WarningOutlineStyled />;
  }
  return <HelpOutlineStyled />;
}
