import React, { useMemo } from 'react';
import styled from 'styled-components';
import { dsnMatchAndReturnProject } from './DisplaySentryLinks';
import useFetch from '../hooks/useFetch';

const SentryLinkHolder = styled.a`
  padding: 0 4px;
  color: ${(props) => props.color || '#c0c0c0'};
  :hover {
    color: #2a94cf;
  }
`;

const SentryNoLink = styled.span`
  padding: 0 4px;
  color: ${(props) => props.color || '#c0c0c0'};
  :hover {
    color: #2a94cf;
  }
`;

// const SentryText = styled.div`
//   color: #c0c0c0;
//   :hover {
//     color: #2a94cf;
//   }
//   text-align: center;
// `;

const SentryHolder = styled.span`
  color: #c0c0c0;
  :hover {
    color: #2a94cf;
  }
  width: 175px;
  display: inline-block;
`;

function useSentryIssueNumber(slug, orgaslug) {
  const query = useMemo(
    () => new URLSearchParams({ organization: orgaslug, slug }),
    [orgaslug, slug]
  );
  const noslug = useMemo(() => query.get('slug') === 'undefined', [query]);
  const url = useMemo(
    () => (noslug ? null : `/api/getSentryIssuesCount/?${query.toString()}`),
    [noslug, query]
  );

  const { data, error } = useFetch(url);
  const issues = parseInt(data?.issueCount, 10);

  const warnLevel = useMemo(() => {
    if (issues >= 50) {
      return 'error';
    }
    if (issues >= 30) {
      return 'warning';
    }
    return 'ok';
  }, [issues]);

  if (noslug || error || Number.isNaN(issues)) {
    return '-';
  }

  return { issues: issues >= 1000 ? `${issues}+` : issues.toString(), warnLevel };
}

function SentryLink({ label, sentryUrl }) {
  const colors = useMemo(
    () => ({
      ok: '#c0c0c0',
      warning: '#e68a00',
      error: '#dc143c',
      unknown: '#d742f5',
    }),
    []
  );

  const sentryProject = useMemo(() => dsnMatchAndReturnProject(sentryUrl), [sentryUrl]);

  const { issues, warnLevel } = useSentryIssueNumber(
    sentryProject?.slug,
    sentryProject?.organization?.slug
  );

  const title = useMemo(
    () =>
      `${
        sentryProject && sentryProject.name ? sentryProject.name : 'Sentry'
      } has ${issues} open issues`,
    [sentryProject, issues]
  );

  const href = useMemo(() => {
    const organization = sentryProject?.organization?.slug;
    const slug = sentryProject?.slug;

    if (!organization || !slug) {
      return undefined;
    }
    return `https://sentry.netsyno.com/${organization}/${slug}`;
  }, [sentryProject]);

  const color = useMemo(() => {
    if (warnLevel in colors) {
      return colors[warnLevel];
    }
    return colors.unknown;
  }, [colors, warnLevel]);

  if (!sentryProject?.slug) {
    return <SentryNoLink color={color}>-</SentryNoLink>;
  }

  if (!href) {
    return (
      <SentryNoLink title={title} color={color}>
        {issues}
      </SentryNoLink>
    );
  }

  return (
    <SentryLinkHolder title={title} href={href} color={color}>
      {label}:{issues}
    </SentryLinkHolder>
  );
}

export default function SentryStats({ sentryUrl }) {
  return (
    <SentryHolder>
      <SentryLink label="sentry" sentryUrl={sentryUrl} />
    </SentryHolder>
  );
}
