import React, {
  useMemo,
  // useCallback, useEffect, useState
} from "react";
import SENTRYPROJECTS from "./SENTRY_PROJECTS.json";

export const SENTRYDSNREGEX = new RegExp(/https:\/\/(.*)\/(\d+)/);

export function dsnMatchAndReturnProject(sentrydsn) {
  const regexmatches = SENTRYDSNREGEX.exec(sentrydsn);
  if (regexmatches) {
    const projectid = regexmatches[2];
    const sentryProjectMatch = SENTRYPROJECTS.find(
      (pro) => pro.id === projectid
    );
    return sentryProjectMatch;
  }
  return null;
}

// curl -H 'Authorization: DSN {DSN}' https://sentry.netsyno.com/api/0/projects/{organization_slug}/{project_slug}/events/
// curl -H 'Authorization: DSN {DSN}' https://sentry.netsyno.com/api/0/projects/{organization_slug}/{project_slug}/issues/?statsPeriod=24h

// function GetSentryIssues({ dsn, org, name }) {
//   const [status, statusSet] = useState(null);

//   const sentryprojurl = useMemo(() => {
//     const organization_slug = org;
//     const project_slug = name;
//     return `https://sentry.netsyno.com/api/0/projects/${organization_slug}/${project_slug}/issues/?statsPeriod=14d`;
//   }, [name, org]);

//   console.log("sentryprojurl", sentryprojurl);

//   const token =
//     "1234";

//   const getEvents = useCallback(async () => {
//     statusSet("loading...");
//     let resp;
//     try {
//       resp = await window.fetch(sentryprojurl, {
//         method: "GET",
//         headers: {
//           Authorization: `Bearer ${token}`,
//           // "Content-Type": "application/json",
//         },
//       });
//     } catch (err) {
//       console.log("error", err);
//       statusSet(`error: ${err}`);
//       return;
//     }

//     const data = await resp.json();
//     console.log(data);
//     statusSet(resp);
//   }, [sentryprojurl, token]);

//   useEffect(() => {
//     getEvents();
//   }, [getEvents]);

//   return <span>{status}</span>;
// }

export default function DisplaySentryLinks({ configurationenv }) {
  const { SENTRY_URL_FRONTEND, SENTRY_URL } = configurationenv;

  const sentryFrontendProject = useMemo(
    () => dsnMatchAndReturnProject(SENTRY_URL_FRONTEND),
    [SENTRY_URL_FRONTEND]
  );
  const sentryBackendProject = useMemo(
    () => dsnMatchAndReturnProject(SENTRY_URL),
    [SENTRY_URL]
  );

  //   <GetSentryIssues
  //             dsn={SENTRY_URL_FRONTEND}
  //             org={sentryFrontendProject.organization.slug}
  //             name={sentryFrontendProject.name}
  //           />{" "}
  //   <GetSentryIssues
  //             dsn={SENTRY_URL}
  //             org={sentryBackendProject.organization.slug}
  //             name={sentryBackendProject.name}
  //           />{" "}

  return (
    <>
      {sentryFrontendProject ? (
        <div>
          <a
            href={`https://sentry.netsyno.com/${
              sentryFrontendProject.organization
                ? sentryFrontendProject.organization.slug
                : ""
            }/${sentryFrontendProject.name}`}
          >
            SENTRYF: {sentryFrontendProject.name}
          </a>
        </div>
      ) : null}
      {sentryBackendProject ? (
        <div>
          <a
            href={`https://sentry.netsyno.com/${
              sentryBackendProject.organization
                ? sentryBackendProject.organization.slug
                : ""
            }/${sentryBackendProject.name}`}
          >
            SENTRYB: {sentryBackendProject.name}
          </a>
        </div>
      ) : null}
    </>
  );
}
