import React, { useMemo } from 'react';
import styled from 'styled-components';
import { bytesToSize } from '../utils';

const Row = styled.div`
  margin: 1px 0;
  line-height: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const FlexRow = styled(Row)`
  :hover {
    background: #f1f1f1;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Overflow = styled.span`
  max-width: 70%;
  display: inline-block;
  margin: 1px 0;
  line-height: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
`;

const duExcludes = ['lost+found', 'total'];

function RenderStorage({ du, maxLinesOfDu }) {
  const orderedDus = useMemo(() => {
    const clonedArr = JSON.parse(JSON.stringify(du));
    const filteredArr = clonedArr.filter(du => !duExcludes.some((el) => du.path.includes(el)))
    return filteredArr.sort((a, b) => b.size - a.size);
  }, [du]);

  const emptyLinesArr =
    maxLinesOfDu - orderedDus.length > 0
      ? Array.apply(null, Array(maxLinesOfDu - orderedDus.length)).map(() => null)
      : [];

  const overallsize = useMemo(
    () => (du && du.length ? bytesToSize(du[du.length - 1].size) : ''),
    [du]
  );
  const relativepath = useMemo(
    () => (du && du.length ? du[du.length - 1].path.replace('./ssds/', '') : ''),
    [du]
  );

  return (
    <div style={{ maxWidth: '100%', fontSize: '0.7em', margin: '10px 0' }}>
      <FlexRow>
        <Overflow title={relativepath}>{relativepath} </Overflow> <span>{overallsize}</span>
      </FlexRow>
      {orderedDus.map((du, idx) => (
        <FlexRow key={idx}>
          <Overflow title={du.path.replace('./ssds/', '')}>
            {du.path.replace('./ssds/', '')}{' '}
          </Overflow>{' '}
          <span>{bytesToSize(du.size)}</span>
        </FlexRow>
      )
      )}
      {emptyLinesArr.map((line, idx) => (
        <Row key={idx} style={{ width: '100%', height: '13px' }}> &nbsp; </Row>
      ))}
    </div>
  );
}

export default RenderStorage;
