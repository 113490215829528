import React, { useMemo } from 'react';
import useSWR from 'swr';
import InopaiMigrationsRun from './InopaiMigrationsRun';
import RenderJsonPre from './RenderJsonPre';

function fetcherNotoken(...args) {
  return window
    .fetch(...args, {
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
    })
    .then((res) => res.json());
}

function useFetchSimple(url) {
  const res = useSWR(url, fetcherNotoken);
  return res;
}

export default function RunInopaiStatusCheck({ projUrl, checkToken, migrationToken }) {
  const statusCheckUrl = useMemo(
    () => `${projUrl}/api/check/?token=${checkToken}&check_migrations=1`,
    [projUrl, checkToken]
  );
  const { data, error } = useFetchSimple(statusCheckUrl);

  if (error) {
    return <div>Failed to load data.</div>;
  }
  if (!data) {
    return <div>Loading...</div>;
  }

  const showRunMigrations = data && data.migrationsOk === false;

  return (
    <div>
      <RenderJsonPre json={data} />
      {showRunMigrations ? (
        <InopaiMigrationsRun projUrl={projUrl} migrationToken={migrationToken} />
      ) : null}
    </div>
  );
}
