import React, { useMemo, useState, useCallback } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';

// function RenderValue({ name, data, path }) {
//   return (
//     <div>
//       {name}: {get(data, path)}
//     </div>
//   );
// }

const Button = styled.button`
  color: #2a94cf;
  border-radius: 3px;
  background-color: #fff;
  padding: 4px 8px;
  border: 1px solid #2a94cf;
  font-weight: 600;
`;

function RenderValueIfBiggerZero({ name, data, path }) {
  const value = useMemo(() => get(data, path), [data, path]);

  if (value === 0) {
    return null;
  }

  return (
    <div>
      {name}: {value}
    </div>
  );
}

function RenderElasticsearchMissingDocuments({ data }) {
  if (!data || typeof data === 'string') {
    return <div>{data}</div>;
  }

  // console.log("data", data);

  return (
    <>
      {/* <RenderValueIfBiggerZero
        name="indicies_not_in_db"
        data={data}
        path="elasticsearch.indicies_not_in_db"
      /> */}
      <RenderValueIfBiggerZero
        name="missing_from_db"
        data={data}
        path="elasticsearch.missing_from_db"
      />
      <RenderValueIfBiggerZero
        name="missing_from_es"
        data={data}
        path="elasticsearch.missing_from_es"
      />
    </>
  );
}

export default function InopaiSearchindexCheckMissing({
  projUrl,
  checkToken,
  label = 'Check searchindex for missing documents',
}) {
  const [status, statusSet] = useState(null);

  const checkMissingUrl = useMemo(
    () => `${projUrl}/api/check/?token=${checkToken}&check_missing=1`,
    [projUrl, checkToken]
  );

  const onClick = useCallback(async () => {
    statusSet('loading...');
    const resp = await window.fetch(
      checkMissingUrl
      //   {
      //   method: "GET",
      //   headers: { "Content-Type": "application/json" },
      //   body: JSON.stringify({ token: migrationToken }),
      // }
    );
    const data = await resp.json();
    statusSet(data);
  }, [checkMissingUrl]);

  return (
    <div>
      <Button type="button" onClick={onClick}>
        {label}
      </Button>
      {status ? <RenderElasticsearchMissingDocuments data={status} /> : null}
    </div>
  );
}
