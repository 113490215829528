function xWeeksAgo(x) {
  const dateo = new Date();
  const past = dateo.getDate() - x * 7;
  dateo.setDate(past);
  return dateo;
}

function xDaysAgo(x) {
  const dateo = new Date();
  const past = dateo.getDate() - x;
  dateo.setDate(past);
  return dateo;
}

function xHoursAgo(x) {
  const dateo = new Date();
  const hourDifferences = x - dateo.getHours();
  const past = dateo.getDate() - Math.ceil(hourDifferences / 24);
  dateo.setDate(past);
  return dateo;
}

function dateobjToDatestr(dateo) {
  return dateo.toISOString().slice(0, 10); // .replace(/-/g, "")
}

export default function getActionlogQueryParams(days) {
  const startdate = dateobjToDatestr(xDaysAgo(days));
  const enddate = dateobjToDatestr(new Date());
  return { startdate, enddate };
}

export function getActionlogQueryParamsAgoModifier(x, group) {
  const startdate = (() => {
    if (group === 'day') {
      return dateobjToDatestr(xDaysAgo(x));
    } else if (group === 'hour') {
      return dateobjToDatestr(xHoursAgo(x));
    } else {
      return dateobjToDatestr(xWeeksAgo(x));
    }
  })();
  const enddate = dateobjToDatestr(new Date());
  return { startdate, enddate };
}

// our size is in MB so we don't neet bytes and KB
const sizes = ['MB', 'GB', 'TB']; // 'Bytes', 'KB',

export function bytesToSize(bytes) {
  if (!bytes) {
    return '';
  }
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
}
