import React from 'react';
import styled from 'styled-components';
import Refresh from '@mui/icons-material/Refresh';
import Home from '@mui/icons-material/Home';
import LinkWithToken from '../components/LinkWithToken';

const InputWrapper = styled.div`
  // position: fixed;
  // top: 0;
  display: flex;
  background: #f1f1f1;
  padding: 0px;
  align-items: center;
  color: #000;
  font-size: 14px;
  z-index: 1;
  width: 100%;
`;

const DateWrapper = styled.div`
  background: #0560f2;
  padding: 4px 16px;
  color: #fff;
  height: 22px;
  display: flex;
  align-items: center;
  margin-right: 16px;
  font-size: 14px;
`;
const HomeLinkHolder = styled(LinkWithToken)`
    padding 0 4px;
    color: #c0c0c0;
    :hover {
        color: #2a94cf;
    }
`;


const ButtonStyled = styled.button`
  font-size: 13px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 24px;
  padding: 0 12px;
  border: none;
  color: #0560f2;
  background: none;
  border-radius: 35px;
  > {
    svg {
      font-size: 17px !important;
      margin-right: 4px;
    }
  }
  :hover {
    background: rgba(5, 96, 242, 0.1);
  }
`

// function useFocusElement() {
//   const inputRef = useRef(null);
//   useEffect(() => {
//     if (
//       inputRef.current // && !isMobile
//     ) {
//       inputRef.current.focus();
//     }
//   }, []);
//   return inputRef;
// }

function Filter({ data, reload, children }) {
  return (
    <InputWrapper>
      <HomeLinkHolder pathname="/">
        <Home />
      </HomeLinkHolder>
      {data.timestamp ? (
        <DateWrapper>{new Date(data.timestamp * 1000).toISOString()}</DateWrapper>
      ) : null}
      <ButtonStyled type="button" onClick={reload} title="Rebuild clustersummary">
        <Refresh />
      </ButtonStyled>
      {/* <Hr /> */}
      {children}
    </InputWrapper>
  );
}

export default Filter;
