import { useCallback, useMemo, useState } from 'react';
import useFetchDeployments from './hooks/useFetchDeployments';
import styled from 'styled-components';
import useSWR from 'swr';
import Check from '@mui/icons-material/Check';
import EXPORTS_OFFLINE_CACHED_RESPONSES from './EXPORTS_OFFLINE_CACHED_RESPONSES.json';
import TokenLoginForm from './TokenLoginForm';

const Divpadded = styled.div`
  padding: 20px;
`;

const Divblockmarginborderbottom = styled.div`
  padding 16px;
  border-bottom: 1px solid black;
`;

function fetcherNotoken(...args) {
  return window
    .fetch(...args, {
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
    })
    .then((res) => res.json());
}

function useFetchSimple(url) {
  const res = useSWR(url, fetcherNotoken);
  return res;
}

function RenderOneExport({ installationdomain, oneexport }) {
  const {
    app_name,
    app_id,
    activationcount,
    group_ids,
    export_id,
    text,
    existing_template_name,
    // semantic_identifier,
    identifier,
  } = oneexport;
  // console.log('existing_template_name', `"${existing_template_name}"`);
  return (
    <div
      style={{
        marginLeft: '16px',
        display: 'grid',
        gridTemplateColumns: '1fr 2fr 130px 150px',
        gridGap: '1rem',
      }}
    >
      <span>
        App {app_id} {app_name}
      </span>
      <a href={`https://${installationdomain}/application/${app_id}/exports/${export_id}/`}>
        Export #{export_id} {text} {identifier}
      </a>
      <span style={{ textAlign: 'right' }} title={group_ids}>
        {activationcount} active groups
      </span>
      <div>
        {existing_template_name && existing_template_name !== 'null' ? (
          <>
            <Check /> {existing_template_name}
          </>
        ) : null}
        {existing_template_name === 'null' ? <>"null"</> : null}
      </div>
      {/* {JSON.stringify(inst)} */}
    </div>
  );
}

function ExportOneUrl({ installationdomain, url, exportsstat }) {
  const apps_count = useMemo(() => exportsstat.length, [exportsstat]);
  const groups_count = useMemo(() => {
    let res = 0;
    exportsstat.forEach((inst) => {
      res += inst.activationcount;
    });
    return res;
  }, [exportsstat]);

  const [show, showSet] = useState(false);
  const showToggle = useCallback(() => showSet((cur) => !cur), []);

  const ordered_exportsstat = useMemo(() => {
    const res = exportsstat.sort((a, b) => {
      const lact = a.activationcount || 0;
      const ract = b.activationcount || 0;
      return lact - ract;
    });
    res.reverse();
    return res;
  }, [exportsstat]);

  return (
    <div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '3fr 130px 100px 100px',
          gridGap: '1rem',
        }}
      >
        <span onClick={showToggle}>{url}</span>
        <span onClick={showToggle} style={{ textAlign: 'right' }}>
          {groups_count} Gruppen
        </span>
        <span onClick={showToggle} style={{ textAlign: 'right' }}>
          {apps_count} Apps
        </span>
        <button type="button" onClick={showToggle}>
          {show ? 'Hide' : 'Show'}
        </button>
      </div>
      {show ? (
        <div>
          {ordered_exportsstat.map((oneexport) => (
            <RenderOneExport
              installationdomain={installationdomain}
              key={oneexport.export_id}
              oneexport={oneexport}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
}

function ExportsCheck({ name, installationdomain }) {
  const { data, error } = useFetchSimple(
    `https://${installationdomain}/api/exportsadmin/?token=67hC8xXKpCsL7zCXmvPcfc97`
  );

  const cachedDataAge = useMemo(
    () => EXPORTS_OFFLINE_CACHED_RESPONSES[installationdomain]?.date,
    [installationdomain]
  );
  const datares = data || EXPORTS_OFFLINE_CACHED_RESPONSES[installationdomain]?.data;

  if (!cachedDataAge) {
    if (error) {
      return null;
      // return <div>Error checking exports {JSON.stringify(error)}</div>;
    }
    if (!data) {
      return null;
    }
  }

  console.log('datares', datares, EXPORTS_OFFLINE_CACHED_RESPONSES);

  return (
    <Divblockmarginborderbottom>
      <h2>
        {installationdomain} ({name}){' '}
        {cachedDataAge ? (
          <span style={{ background: 'red', color: '#fff' }}>
            Warning: this is cached data from {cachedDataAge}
          </span>
        ) : null}
      </h2>
      <div>
        {Object.keys(datares).map((url) => (
          <ExportOneUrl
            installationdomain={installationdomain}
            exportsstat={datares[url]}
            url={url}
          />
        ))}
      </div>
    </Divblockmarginborderbottom>
  );
  // return <div>{JSON.stringify(data, undefined, 2)}</div>;
}

function CalculateExportsSummary({ deployments }) {
  const inopais = useMemo(
    () => deployments.filter((dep) => dep.kind.includes('inopai')),
    [deployments]
  );

  console.log('inopais', inopais);

  return inopais.map((dep) => (
    <ExportsCheck
      key={dep.installationdomain}
      name={dep.name}
      installationdomain={dep.installationdomain}
    />
  ));
}

export default function ExportsSummary() {
  const { filtereddata, error } = useFetchDeployments();

  if (error) {
    return <TokenLoginForm />;
  }
  if (!filtereddata) {
    return <Divpadded>Loading...</Divpadded>;
  }
  return <CalculateExportsSummary deployments={filtereddata} />;
}
