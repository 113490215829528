
export function colorForName(name) {
    if (name.includes('beta')) {
        return 'magenta';
    }
    if (name.includes('dev') && !name.includes('venturedevcom')) {
        return 'magenta';
    }
    if (name.includes('backup')) {
        return 'gray';
    }
    if (name.includes('ceph-mds')) {
        return 'red';
    }
    if (name.includes('traefik')) {
        return 'red';
    }
    if (name.includes('elasticsearch')) {
        return 'red';
    }
    return 'black';
}

export function iconForNameAndNs(name, ns) {
    if (SYSTEM_NS.includes(ns)) {
        return '💻'
    }
    if (name.includes('postgres')) {
        return '🐘'
    }
    return null
}

export const SYSTEM_NS = [
    'rook-ceph',
    'metallb-system',
    'kube-system',
    'kubernetes-dashboard',
    'kube-fledged',
    'gitlab',
    'gitlab-runner',
    'gitlab-runner-fast',
    'default',
    'nextcloud',
    'prometheus-agent',
    'tralios-kube-state-metrics',
    'tralios-traefik',
    'elastic-system',
    // NETSYNO
    'netsyno-sentry',
    'netsyno-deploymentdocumentation',
    'netsyno-elk-stack',
    'calculate-storage',
]
