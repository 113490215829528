import React, { useEffect } from 'react';
import Deployments from './Deployments';
import { mutate } from 'swr';

import styled from 'styled-components';
import useFetchDeployments from './hooks/useFetchDeployments';
import LinkWithToken from './components/LinkWithToken';
import TokenLoginForm from './TokenLoginForm';

// import GitlabymlCollection from './GitlabymlCollection';
// <GitlabymlCollection />

const Divpadded = styled.div`
  padding: 20px;
`;

export default function MainComponent() {
  const { filtereddata, error } = useFetchDeployments();

  useEffect(() => {
    mutate('/api/deployments');
  }, []);

  if (error) {
    return <TokenLoginForm />;
  }
  if (!filtereddata) {
    return <Divpadded>...</Divpadded>;
  }
  return (
    <>
      <LinkWithToken pathname="/inopaimergerequests/">INOPAI Merge Requests</LinkWithToken>{' '}
      <LinkWithToken pathname="/clustersummary/">Clustersummary</LinkWithToken>{' '}
      <LinkWithToken pathname="/exportssummary/">Exportssummary</LinkWithToken>
      <LinkWithToken pathname="/hostinganalysis/">Hosting analysis</LinkWithToken>
      <Deployments deployments={filtereddata} />
    </>
  );
}
