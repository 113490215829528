import { useMemo, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';

export default function useQuery(options) {
  const { search } = useLocation();
  const query = useMemo(
    () => qs.parse(search, { ignoreQueryPrefix: true, ...options }),
    [search, options]
  );
  return query;
}

// return search string e.g. `?a=1&b=2`
export function queryStringify(query, options) {
  return qs.stringify(query, { indices: false, addQueryPrefix: true, ...options });
}

export function useSetSearchQuery() {
  const navigate = useNavigate();
  const query = useQuery();
  const location = useLocation();

  const setSearchQuery = useCallback(
    (newQuery, options?) => {
      const q = { ...query, ...newQuery };
      Object.keys(q)
        .filter((k) => !q[k])
        .forEach((k) => {
          delete q[k];
        });
      navigate(
        {
          ...location,
          search: queryStringify(q, { arrayFormat: 'comma', encode: false }),
        },
        options
      );
    },
    [location, navigate, query]
  );

  return { setSearchQuery };
}
