import React from "react";
import {
  Tooltip,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";
import { BarChart, Bar, LineChart, Line } from "recharts";
import styled from "styled-components";

const ChartHolder = styled.div`
  display: flex;
  flex-basis: 100%;
  flex-flow: column nowrap;
  width: 100%;
  max-width: ${({ isBigSize }) => (isBigSize ? "950px" : "500px")};
  height: ${({ isBigSize }) => (isBigSize ? "400px" : "140px")};
  // border: 1px solid #ccc;
`;

const TinyChartLabel = styled.p`
  font-size: 18px;
  font-weight: bold;
  padding-left: 30px;
`;

export default function ProjectDetailChart({
  label,
  labelKey,
  color = "#82ca9d",
  type = "AreaChart",
  dataResult,
  isBigSize = false,
}) {
  return (
    <ChartHolder isBigSize={isBigSize}>
      <TinyChartLabel>{label}</TinyChartLabel>
      {type === "BarChart" ? (
        <ResponsiveContainer>
          <BarChart data={dataResult}>
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <XAxis dataKey="date" hide />
            <YAxis />
            <Bar dataKey={labelKey} fill={color} isAnimationActive={false} />
          </BarChart>
        </ResponsiveContainer>
      ) : null}
      {type === "LineChart" ? (
        <ResponsiveContainer>
          <LineChart data={dataResult}>
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <XAxis dataKey="date" hide />
            <YAxis />
            <Line
              type="monotone"
              dataKey={labelKey}
              stroke={color}
              isAnimationActive={false}
            />
          </LineChart>
        </ResponsiveContainer>
      ) : null}
    </ChartHolder>
  );
}
