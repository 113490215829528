import React, { useMemo } from 'react';
import {
  AreaChart,
  Area,
  Tooltip, // CartesianGrid, XAxis,
  ResponsiveContainer,
} from 'recharts';
import styled from 'styled-components';

const AreaChartHolder = styled.div`
  display: inline-block;  
  width: 70px;
  height: 20px;
  // border: 1px solid #ccc;
  margin-right: 10px;
`;

function calculateValuesSum(obj) {
  let res = 0;
  Object.keys(obj).forEach((logtype) => {
    const val = obj[logtype];
    res += val;
  });
  return res;
}

export default function QueryLogsRenderSparkline({ actionlogsresp }) {
  // console.log("actionlogsresp", actionlogsresp);

  const dataResult = useMemo(() => {
    if (!actionlogsresp) {
      return null;
    }
    if (!Object.keys(actionlogsresp)) {
      return null;
    }

    const data = [];
    Object.keys(actionlogsresp).forEach((date) => {
      const val = actionlogsresp[date];
      const dataElement = {
        date,
        count: calculateValuesSum(val),
        alldata: val,
      };
      data.push(dataElement);
    });

    // console.log("data", data);

    return data;
  }, [actionlogsresp]);

  const sumevents = useMemo(() => {
    if (!dataResult || !dataResult.length) {
      return null;
    }
    const thesum = dataResult.map((ent) => ent.count).reduce((a, b) => a + b, 0);
    if (String(thesum) === 'NaN') {
      return null;
    }
    return thesum === undefined ? null : thesum;
  }, [dataResult]);

  if (!actionlogsresp || !Object.keys(actionlogsresp)) {
    return null;
  }

  if (actionlogsresp.status === 'loading') {
    return <div>...</div>;
  }

  if (actionlogsresp.error) {
    return <div>{actionlogsresp.error}</div>;
  }

  if (!sumevents) {
    return (
      <div>
        No events last 3 days
      </div>
    );
  }
  return (
    <div style={{ height: '32px' }}>
      <span>{`${sumevents}`}</span>
      <AreaChartHolder isAnimationActive={false}>
        <ResponsiveContainer>
          <AreaChart data={dataResult} isAnimationActive={false}>
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            <Tooltip />
            {/* <XAxis dataKey="date" hide /> */}
            <Area
              type="monotone"
              dataKey="count"
              stroke="#82ca9d"
              fill="#82ca9d"
              isAnimationActive={false}
            />
          </AreaChart>
        </ResponsiveContainer>
      </AreaChartHolder>
    </div>
  );
}
