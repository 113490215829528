import React, { useMemo, useState, useCallback } from 'react';
import styled from 'styled-components';

// function RenderValue({ name, data, path }) {
//   return (
//     <div>
//       {name}: {get(data, path)}
//     </div>
//   );
// }

const Button = styled.button`
  color: #2a94cf;
  border-radius: 3px;
  background-color: #fff;
  padding: 4px 8px;
  border: 1px solid #2a94cf;
  font-weight: 600;
`;

function RenderElasticsearchRes({ data }) {
  if (!data || typeof data === 'string') {
    return <div>{data}</div>;
  }

  // console.log("data", data);

  return <div>{JSON.stringify(data)}</div>;
}

export default function InopaiSearchindexCheckFix({
  projUrl,
  checkToken,
  label = 'Fix searchindex (PLEASE BE CAREFUL!)',
}) {
  const [status, statusSet] = useState(null);

  const checkMissingUrl = useMemo(() => `${projUrl}/api/check_searchindex/?fix=1`, [projUrl]);

  const onClick = useCallback(async () => {
    statusSet('loading...');
    const resp = await window.fetch(checkMissingUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: `Token ${checkToken}` },
      //   body: JSON.stringify({ token: migrationToken }),
    });
    const data = await resp.json();
    statusSet(data);
  }, [checkMissingUrl, checkToken]);

  return (
    <div>
      <Button
        type="button"
        onClick={() => {
          window.confirm('Bist du sicher? diese Operation kann teuer sein!') && onClick();
        }}
      >
        {label}
      </Button>
      {status ? <RenderElasticsearchRes data={status} /> : null}
    </div>
  );
}
