import React from 'react';
import InopaiSearchindexQuickcheck from './InopaiSearchindexQuickcheck';
import InopaiSearchindexCheckMissing from './InopaiSearchindexCheckMissing';
import InopaiSearchindexCheckFix from './InopaiSearchindexCheckFix';

import RunInopaiStatusCheck from './RunInopaiStatusCheck';

export default function CheckInstallationStatus({ projUrl, label = 'CHECK' }) {
  const checkToken = '67hC8xXKpCsL7zCXmvPcfc97';

  return (
    <div>
      <RunInopaiStatusCheck projUrl={projUrl} checkToken={checkToken} migrationToken={checkToken} />
      <InopaiSearchindexQuickcheck projUrl={projUrl} checkToken={checkToken} />
      <InopaiSearchindexCheckMissing projUrl={projUrl} checkToken={checkToken} />
      <InopaiSearchindexCheckFix projUrl={projUrl} checkToken={checkToken} />
    </div>
  );
}
