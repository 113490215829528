export default function fetcher(...args) {
  // if (!args.url) {
  //   return null;
  // }
  const token = window.location.hash.replace('#token=', '');
  return window
    .fetch(...args, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => res.json());
}
