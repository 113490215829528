import React, { useState } from 'react';

import styled from 'styled-components';


/*
 * PROPOSAL for better color names
 *
 * We want to try to create a more reusable naming for our colors, which hopefully makes
 * theming and adoption easier.
 */
const colors = {
  // altPrimaryColor: '#208dd5',
  primary: '#497391',
  accent: '#5181a2',
  // darkerPrimary: '#375567',
  textOnPrimary: '#ffffff',

  // warningColor: '#f41617',
  // warningColorHover: '#c31415',
  // secondaryWarningColor: '#47a9e0',
  // modernWarningColor: '#e35657',
  // modernSuccessColor: '#219653',

  // The main background and foreground colors
  // For a light theme background should be white-ish and foreground should be black-ish
  // For a dark theme it goes the other way round.
  background: '#ffffff',
  backgroundHover: '#e6e6e6',
  backgroundActive: '#cccccc',
  backgroundActiveHover: '#b3b3b3',

  // backgroundTransparent: 'transparent',
  // foreground: '#555555',
  // darkerForeground: '#070909',
  // lightAccent: '#eaeaea',

  // borderColor: '#929799',
  // borderAlt: '#d2d2d2',

  // success20: 'rgba(0, 255, 0, 0.2)',
  // success10: 'rgba(0, 255, 0, 0.1)',

  // // colors for user feedback
  // required: '#770404',
  // warning: '#f82400',
  // success: '#00ff00',
  // inputBackground: '#ffffff',
};
const paddingButton = '0.4em 0.9em';
const button = {
  default: {
    fontSize: '1.1em',
    borderRadius: '0px',
    padding: paddingButton,
    color: `${colors.foreground}`,
    backgroundColor: 'transparent',
    border: `1px solid ${colors.primary}`,

    '&:hover, &:active, &:focus': {
      backgroundColor: colors.gray6,
      border: `1px solid ${colors.primary}`,
      color: colors.foreground,
    },
  },

  // defaultDisabled: {
  //   fontSize: '1.1em',
  //   borderRadius: '0px',
  //   padding: paddingButton,
  //   color: `${colors.gray5}`,
  //   backgroundColor: 'transparent',
  //   border: `1px solid ${colors.gray5}`,
  // },

  primary: {
    fontSize: '1.1em',
    borderRadius: '0px',
    padding: paddingButton,

    backgroundColor: colors.primary,
    border: `1px solid ${colors.primary}`,
    color: colors.textOnPrimary,

    '&:hover, &:active, &:focus': {
      backgroundColor: colors.accent,
    },
  },
}


const ClickableCollapseWrap = styled.div`  margin: 0 0.5em 0.8em 0.7em;
background: ${() => colors.primary}
&:hover {
  background: ${({ isOpen }) =>
    isOpen ? colors.textOnPrimary : colors.accent};
}
&:active {
  ${({ isOpen }) => (isOpen ? colors.textOnPrimary : colors.accent)};
}
&:focus {
  ${({ isOpen }) => (isOpen ? colors.textOnPrimary : colors.accent)};
}

  ${({ isOpen }) => (isOpen ? button.default : button.primary)} cursor: pointer;
`;



export default function CollapseChildren({ label, children, open = false }) {
  const [isOpen, setIsOpen] = useState(open);
  return (
    <>
      <ClickableCollapseWrap isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <span>{label}</span>
        <div style={{ paddingRight: '8px' }}>
          {isOpen ? '^' : '°'}
        </div>
      </ClickableCollapseWrap>
      {isOpen ? children : null}
    </>
  );
}
