import React from 'react';
import styled from 'styled-components';
import AllInclusive from '@mui/icons-material/AllInclusive';
import GitHub from '@mui/icons-material/GitHub';
import SentryStats from './SentryStats';
import LinkWithToken from './LinkWithToken';

const GitHubLinkHolder = styled.a`
  padding: 0 4px;
`;
const KubectLinkHolder = styled.a`
  padding: 0 4px;
  color: #c0c0c0;
  :hover {
    color: #2a94cf;
  }
`;
const GitHubStyled = styled(GitHub)`
  color: #c0c0c0;
  :hover {
    color: #2a94cf;
  }
`;
const LinkDomain = styled.span`
  color: #2a94cf;
  text-decoration: none;
  font-weight: 600;
  word-break: break-all;

  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 350px;
`;
const MissingLinkDomain = styled.span`
  color: #be2117;
  text-decoration: none;
  font-weight: 600;
  word-break: break-all;
`;
const DetailsLink = styled(LinkWithToken)`
  display: inline;
  padding: 0 4px;
`;

export default function ProjectHeaderIcons({ project, kubectllink, projUrl }) {
  return (
    <>
      <GitHubLinkHolder
        href={`https://gitlab.netsyno.com/${project.path_with_namespace}`}
        title={`Gitlab ${project.path_with_namespace}`}
      >
        <GitHubStyled fontSize="small" />
      </GitHubLinkHolder>
      <KubectLinkHolder href={kubectllink} title={`Kubectl ${project.name}`}>
        <AllInclusive fontSize="small" />
      </KubectLinkHolder>
      <SentryStats
        // sentryUrlFrontend={project?.configurationenv?.SENTRY_URL_FRONTEND}
        sentryUrl={project?.configurationenv?.SENTRY_URL}
      />
      <DetailsLink pathname={`/p/${project.projectid}/`}>
        {/* <ShowDetailsButton type="button">details</ShowDetailsButton> */}
        {project.installationdomain ? (
          <span title={`${project.kind} (projectid: ${project.projectid})`}>
            <LinkDomain style={{ wordBreak: 'break-all' }} href={projUrl} title={projUrl}>
              {project.installationdomain}
            </LinkDomain>
          </span>
        ) : (
          <MissingLinkDomain style={{ wordBreak: 'break-all' }}>
            installationdomain MISSING for {project.path_with_namespace}
          </MissingLinkDomain>
        )}
      </DetailsLink>
    </>
  );
}
