import { useMemo } from 'react';

export default function useProjectProps(project) {
  const kubectllink = useMemo(() => {
    const namespacestart = `${project.name.toLowerCase()}-`;
    return `http://127.0.0.1:8001/api/v1/namespaces/kubernetes-dashboard/services/https:kubernetes-dashboard:/proxy/#/search?namespace=_all&q=${namespacestart}`;
  }, [project.name]);

  const projUrl = useMemo(
    () => `https://${project.installationdomain}`,
    [project.installationdomain]
  );

  return { kubectllink, projUrl };
}
