import { useCallback, useEffect, useState } from 'react';
import useFetch from './useFetch';

const statusEnum = {
  ok: 0,
  warning: 1,
  error: 2,
  unknown: 3,
  loading: 999,
  0: 'ok',
  1: 'warning',
  2: 'error',
  3: 'unknown',
};

export function useProjectQuickStatus(projUrl) {
  const [url, setUrl] = useState(undefined);

  const doStatusCall = useCallback(() => {
    const checkToken = '67hC8xXKpCsL7zCXmvPcfc97';
    setUrl(`${projUrl}/api/check/?token=${checkToken}&check_migrations=1&check_elasticsearch=1`);
  }, [projUrl]);

  const { data, error } = useFetch(url);

  useEffect(() => {
    if (error) {
      console.warn(
        `WARNING in useProjectQuickStatus.js for ${projUrl}: Querying check API failed.`,
        { url, data, error }
      );
    }
  }, [error, data, url, projUrl]);

  useEffect(() => {
    // if (kind.includes('inopai')) {
    doStatusCall();
    // }
  }, [doStatusCall]);

  const [status, setStatus] = useState(statusEnum[statusEnum.loading]);
  const [queryStatus, setQueryStatus] = useState(statusEnum.unknown);
  const [migrationsStatus, setMigrationsStatus] = useState(statusEnum.unknown);
  const [elasticsearchStatus, setElasticsearchStatus] = useState(statusEnum.unknown);
  const [messages, setMessages] = useState({});
  const [tooltip, setTooltip] = useState(null);

  const print = useCallback((key, text) => {
    setMessages((prevMessages) => ({ ...prevMessages, [key]: text }));
  }, []);

  // query status
  useEffect(() => {
    if (error) {
      setQueryStatus(statusEnum.error);
      print('connection', JSON.stringify(error));
    } else if (!data && !error) {
      setQueryStatus(statusEnum.unknown);
      print('connection', 'no status data available');
    } else {
      setQueryStatus(statusEnum.ok);
      print('connection', undefined);
    }
  }, [data, error, print]);

  // migration status
  useEffect(() => {
    if (data?.migrationsOk === true) {
      setMigrationsStatus(statusEnum.ok);
      print('migration', undefined);
    } else if (data?.migrationsOk === false) {
      setMigrationsStatus(statusEnum.error);
      print('migration', 'migrations needed');
    } else {
      setMigrationsStatus(statusEnum.unknown);
      print('migration', 'unknown migrations status');
    }
  }, [data, print]);

  // elasticsearch status
  useEffect(() => {
    let newStatus = -1;

    if (data?.elasticsearchOk) {
      newStatus = Math.max(newStatus, statusEnum.ok);
    }

    const health = data?.elasticsearch?.health;
    if (Array.isArray(health)) {
      health.forEach((v) => {
        if (v?.status === 'green') {
          newStatus = Math.max(newStatus, statusEnum.ok);
        } else if (v?.status === 'yellow') {
          console.log('STATUS YELLOW', v);
          newStatus = Math.max(newStatus, statusEnum.warning);
        } else if (v?.status === 'red') {
          console.log('STATUS RED', v);
          newStatus = Math.max(newStatus, statusEnum.error);
        }
      });
    }

    setElasticsearchStatus(newStatus === -1 ? statusEnum.unknown : newStatus);
    if (newStatus === -1) {
      print('es', 'unknown');
    } else if (newStatus === statusEnum.warning) {
      print('es', 'yellow');
    } else if (newStatus === statusEnum.error) {
      print('es', 'red');
    } else if (newStatus === statusEnum.ok) {
      print('es', undefined);
    }
  }, [data, print]);

  // status aggregation
  useEffect(() => {
    const newStatus = Math.max(queryStatus, migrationsStatus, elasticsearchStatus);

    // console.log({
    //   messages,
    //   filtered: Object.keys(messages).filter(key => messages.key !== undefined),
    // });
    const tooltip = Object.keys(messages)
      .filter((key) => messages[key] !== undefined)
      .map((key) => `${key}: ${messages[key]}`)
      .join('; ');

    setStatus(statusEnum[newStatus]);
    setTooltip(tooltip);
  }, [queryStatus, migrationsStatus, elasticsearchStatus, messages]);

  return { status, tooltip, messages };
}
