import React, { useMemo, useState, useCallback } from "react";
export default function InopaiMigrationsRun({
  projUrl,
  migrationToken,
  label = "Run migrations",
}) {
  const [status, statusSet] = useState(null);

  const doMigrateUrl = useMemo(() => `${projUrl}/api/migrate/`, [projUrl]);

  const onClick = useCallback(async () => {
    statusSet("loading...");
    const resp = await window.fetch(doMigrateUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token: migrationToken }),
    });
    statusSet(resp);
  }, [doMigrateUrl, migrationToken]);

  return (
    <div>
      <button
        type="button"
        onClick={onClick}
        style={{ minWidth: "220px", textAlign: "left" }}
      >
        {label}
      </button>
      {status ? <pre>{JSON.stringify(status)}</pre> : null}
    </div>
  );
}
