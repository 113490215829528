import { useMemo } from 'react';
import useFetch from './useFetch';

export default function useFetchDeployments() {
  const { data, error } = useFetch('/api/deployments');

  const filtereddata = useMemo(() => {
    if (!data) {
      return data;
    }
    return data;
    // console.log(data);
    // return data.filter((ent) => {
    //   if (EXCLUDES.includes(ent.installationdomain)) {
    //     return false;
    //   }
    //   return true;
    // });
  }, [data]);

  return { filtereddata, error };
}
