import { useMemo, createContext } from 'react';
import { useLocation } from 'react-router-dom';

const TokenContext = createContext(null);

export default TokenContext;

export function TokenContextProvider({ children }) {
  const location = useLocation();

  const token = useMemo(() => {
    return location.hash ? location.hash.replace('#token=', '') : null;
  }, [location]);
  // console.log('token', token);

  return <TokenContext.Provider value={token}>{children}</TokenContext.Provider>;
}
