import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import MainComponent from './MainComponent';
import ProjectDetail from './ProjectDetail';
import ClusterSummaryComponent from './ClusterSummaryComponent';
import ExportsSummary from './ExportsSummary';
import { TokenContextProvider } from './TokenContext';
import InopaiissuesPage from './pages/InopaiissuesPage';
import InopaimergerequestsPage from './pages/InopaimergerequestsPage';

export default function App() {
  return (
    <Router>
      <TokenContextProvider>
        <Routes>
          {/* fix me, use only one route, if token can be read by exact path also */}
          <Route exact path="/" element={<MainComponent />} />
          <Route path="/home/" element={<MainComponent />} />
          <Route path="/inopaiissues/" element={<InopaiissuesPage />} />
          <Route path="/inopaimergerequests/" element={<InopaimergerequestsPage />} />
          <Route path="/exportssummary/" element={<ExportsSummary />} />
          <Route path="/p/:projectId/" element={<ProjectDetail />} />
          <Route path="/clustersummary/" element={<ClusterSummaryComponent />} />
        </Routes>
      </TokenContextProvider>
    </Router>
  );
}
