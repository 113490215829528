import React, { useMemo, useState, useCallback, useEffect } from 'react';
import RenderJsonPre from './RenderJsonPre';
import styled from 'styled-components';
import CollapseChildren from './components/CollapseChildren'

// function RenderValue({ name, data, path }) {
//   return (
//     <div>
//       {name}: {get(data, path)}
//     </div>
//   );
// }

// function RenderValueElasticsearchCalculateIndexSum({ name, data, path }) {
//   const val = useMemo(() => {
//     const value = get(data, path);
//     return value;
//   }, [data, path]);
//   return (
//     <div>
//       {name}: {val}
//     </div>
//   );
// }

// const Block = styled.div`
//   font-size: 15px;
//   padding: 5px;
//   border-radius: 3px;
//   margin: 10px 0;
//   border: none;
//   border: 1px solid #c0c0c0;
// `;

const Button = styled.button`
  color: #2a94cf;
  border-radius: 3px;
  background-color: #fff;
  padding: 4px 8px;
  border: 1px solid #2a94cf;
  font-weight: 600;
`;

function convertUnit(size_in_bytes, unit) {
  if (unit === 'KB') {
    return size_in_bytes / 1024;
  }
  if (unit === 'MB') {
    return size_in_bytes / (1024 * 1024);
  }
  if (unit === 'GB') {
    return size_in_bytes / (1024 * 1024 * 1024);
  }
}

function RenderMb({ size }) {
  return <span>{Math.round(convertUnit(size, 'KB'), 2)} MB</span>;
}

function RenderElasticsearchIndexSummary({ index }) {
  const indexesInfo = useMemo(() => {
    const indices = Object.keys(index);

    if (!indices || !indices.length) {
      return null;
    }

    const indexCount = indices.length;
    let overallSize = 0;

    indices.forEach((key) => {
      const data = index[key];
      overallSize += data.total.store.size_in_bytes;
      //   docs += data.total.docs.count;
    });

    // console.log("index", index);

    return {
      indexCount,
      //   overallSize,
      overallSizeInMB: Math.round(convertUnit(overallSize, 'MB'), 4),
    };
  }, [index]);

  const indexesInfoentries = useMemo(() => {
    const indices = Object.keys(index);

    if (!indices || !indices.length) {
      return [];
    }

    const lst = [];

    indices.forEach((key) => {
      const data = index[key];
      const ent = {
        indexname: key,
        store__size_in_bytes: data.total.store.size_in_bytes,
        docs__count: data.total.docs.count,
        docs__deleted: data.total.docs.deleted,
        fielddata__memory_size_in_bytes: data.total.fielddata.memory_size_in_bytes,
        indexing__index_time_in_millis: data.total.indexing.index_time_in_millis,
        query_cache__cache_count: data.total.query_cache.cache_count,
        refresh__total_time_in_millis: data.total.refresh.total_time_in_millis,
        search__open_contexts: data.total.search.open_contexts,
        search__query_current: data.total.search.query_current,
        search__query_total: data.total.search.query_total,
        search__scroll_current: data.total.search.scroll_current,
        search__fetch_current: data.total.search.fetch_current,
        search__fetch_total: data.total.search.fetch_total,
        translog__size_in_bytes: data.total.translog.size_in_bytes,
      };
      lst.push(ent);
    });

    lst.sort((a, b) =>
      a.color > b.color
        ? 1
        : a.color === b.color
          ? a.store__size_in_bytes > b.store__size_in_bytes
            ? -1
            : 1
          : -1
    );

    return lst;
  }, [index]);

  if (!indexesInfo) {
    return <div>No elasticsearch info!</div>;
  }

  return (
    <div>
      <table border={1}>
        <thead>
          <tr>
            <th>indexname</th>
            <th>store size_in_bytes</th>
            <th>docs count</th>
            <th>docs deleted</th>
            <th>fielddata memory_size_in_bytes</th>
            <th>indexing index_time_in_millis</th>
            <th>query_cache cache_count</th>
            <th>refresh total_time_in_millis</th>
            <th>search open_contexts</th>
            <th>search query_current</th>
            <th>search query_total</th>
            <th>search scroll_current</th>
            <th>search fetch_current</th>
            <th>search fetch_total</th>
            <th>translog size_in_bytes</th>
          </tr>
        </thead>
        <tbody>
          {indexesInfoentries.map((ent) => {
            const {
              indexname,
              store__size_in_bytes,
              docs__count,
              docs__deleted,
              fielddata__memory_size_in_bytes,
              indexing__index_time_in_millis,
              query_cache__cache_count,
              refresh__total_time_in_millis,
              search__open_contexts,
              search__query_current,
              search__query_total,
              search__scroll_current,
              search__fetch_current,
              search__fetch_total,
              translog__size_in_bytes,
            } = ent;
            return (
              <tr key={indexname}>
                <td>
                  <b>{indexname}</b>
                </td>
                <td>
                  <RenderMb size={store__size_in_bytes} />
                </td>
                <td>{docs__count}</td>
                <td>{docs__deleted}</td>
                <td>
                  <RenderMb size={fielddata__memory_size_in_bytes} />
                </td>
                <td>{indexing__index_time_in_millis}</td>
                <td>{query_cache__cache_count}</td>
                <td>{refresh__total_time_in_millis}</td>
                <td>{search__open_contexts}</td>
                <td>{search__query_current}</td>
                <td>{search__query_total}</td>
                <td>{search__scroll_current}</td>
                <td>{search__fetch_current}</td>
                <td>{search__fetch_total}</td>
                <td>
                  <RenderMb size={translog__size_in_bytes} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <RenderJsonPre json={indexesInfo} />
    </div>
  );
}

function RenderElasticsearchNodesSummary({ nodes }) {
  const nodeInfo = useMemo(() => {
    if (!nodes) {
      return null;
    }
    const nodeids = Object.keys(nodes);
    if (nodeids.length !== 1) {
      return null;
    }
    const nodeinfo = nodes[nodeids];
    return nodeinfo;
  }, [nodes]);

  if (!nodeInfo) {
    return <pre>{JSON.stringify(nodes, undefined, 2)}</pre>;
  }
  //   console.log("nodeInfo", nodeInfo);
  return <div>ESversion:{nodeInfo.version}</div>;
}

function RenderElasticsearchMissingDocuments({ data }) {
  // console.log(JSON.stringify(data, undefined, 2));

  const healthReport = useMemo(
    () =>
      data?.elasticsearch?.health
        ? data.elasticsearch.health
          .map((v, idx) => `${idx}::${v?.cluster}: ${v?.status}`)
          .join('; ')
        : 'unknown',
    [data]
  );

  if (!data || typeof data === 'string') {
    return data;
  }

  const { elasticsearch, elasticsearchOk } = data;



  return (
    <CollapseChildren label={`elasticsearch is ok: ${elasticsearchOk ? 'YES' : 'NO'} ${healthReport} Show Details`}>
      {elasticsearch ? <RenderElasticsearchIndexSummary index={elasticsearch.index} /> : null}
      {elasticsearch ? <RenderElasticsearchNodesSummary nodes={elasticsearch.nodes} /> : null}
    </CollapseChildren>
  );
  //   return (

  //     <>
  //       <RenderValueElasticsearchCalculateIndexSum
  //         name="indicies_not_in_db"
  //         data={data}
  //         path="elasticsearch.indicies_not_in_db"
  //       />
  //       <RenderValue
  //         name="missing_from_db"
  //         data={data}
  //         path="elasticsearch.missing_from_db"
  //       />
  //       <RenderValue
  //         name="missing_from_es"
  //         data={data}
  //         path="elasticsearch.missing_from_es"
  //       />
  //     </>
  //   );
}

export default function InopaiSearchindexQuickcheck({
  projUrl,
  checkToken,
  label = 'Check searchindex',
}) {
  const [status, statusSet] = useState(null);

  const checkMissingUrl = useMemo(
    () => `${projUrl}/api/check/?token=${checkToken}&check_elasticsearch=1`,
    [projUrl, checkToken]
  );

  const onClick = useCallback(async () => {
    statusSet('Loading...');
    const resp = await window.fetch(
      checkMissingUrl
      //   {
      //   method: "GET",
      //   headers: { "Content-Type": "application/json" },
      //   body: JSON.stringify({ token: migrationToken }),
      // }
    );
    const data = await resp.json();
    statusSet(data);
  }, [checkMissingUrl]);

  useEffect(() => {
    onClick();
  }, [onClick]);

  return (
    <>
      <Button type="button" onClick={onClick}>
        {label}
      </Button>
      {status ? <RenderElasticsearchMissingDocuments data={status} /> : null}
    </>
  );
}
