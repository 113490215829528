import React, { useMemo } from 'react';
import styled from 'styled-components';
// import ReportIcon from '@mui/icons-material/Report';
// import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {
  colorForName,
  // iconForNameAndNs,
} from './helperfunctions'
import useQuery from '../useQuery';

const Row = styled.div`
  margin: 1px 0;
  line-height: 13px;
  overflow: hidden;
  :hover {
    background: #f1f1f1;
  };
  border: 1px solid gray;
`;

const orderingDefinition = {
  traefik: 1,
  'rook-ceph-mds': 2,
  elasticsearch: 3,
  postgres: 4,
};

function sortPods(l, r) {
  const mostlikelymatchL = Object.keys(orderingDefinition).some((namepart) =>
    l.name.includes(namepart)
  );
  if (!mostlikelymatchL) {
    return l > r;
  }
  const orderingL = orderingDefinition[mostlikelymatchL];

  const mostlikelymatchR = Object.keys(orderingDefinition).some((namepart) =>
    r.name.includes(namepart)
  );
  if (!mostlikelymatchR) {
    return l > r;
  }
  const orderingR = orderingDefinition[mostlikelymatchR];

  const diff = orderingL - orderingR;
  return diff;
}

const HIDE_SYS_NAMESPACES = [
  "gitlab",
  "gitlab-runner-fast",
  "gitlab-runner",
  "rook-ceph",
  "velero",
  "kube-system",
  "metallb-system",
  "kubernetes-dashboard",
  "prometheus-agent",
  "tralios-traefik",
]

function RenderOnePod({
  name,
  ns,
  prio,
  container_statuses,
  container_specs,
  volumes,
  markRestartedPods,
  showPodName,
  showStopped,
  showDateStarted,
  showVolumes,
  showSysPods,
}) {
  const color = useMemo(() => colorForName(name)
    , [name]);
  // const icon = useMemo(() => iconForNameAndNs(name, ns), [ns, name])

  const lnk = useMemo(
    () =>
      `http://127.0.0.1:8001/api/v1/namespaces/kubernetes-dashboard/services/https:kubernetes-dashboard:/proxy/#/pod/${ns}/${name}?namespace=${ns}`,
    [name, ns]
  );

  // console.log(name, container_statuses, ns, container_specs)

  const hasRestarts = useMemo(() => {
    if (!container_statuses) {
      return false;
    }
    let hasR = 0;
    container_statuses.forEach((cont) => {
      if (cont.restart_count) {
        hasR += cont.restart_count;
      }
    });
    return hasR;
  }, [container_statuses]);

  const style = useMemo(() => {
    const stylebase = {
      color,
      fontWeight: prio < 4 ? 'bold' : 'normal',
      textDecoration: 'None',
      width: '100%',
      display: 'grid',
      gridTemplateColumns: '65px 100px 1fr',
      gridGap: '0 1rem',
      // marginBottom: '8px',
    };
    if (hasRestarts && markRestartedPods) {
      stylebase.background = 'orange';
    }
    return stylebase;
  }, [color, prio, hasRestarts, markRestartedPods]);

  const namedisplay = useMemo(() => {
    if (container_statuses && container_statuses.length === 1) {
      const st = container_statuses[0];
      if (st.started) {
        if (showPodName) {
          return `${st.name} (${name})`;
        }
        return st.name
      }
      return `STOPPED ${st.name}`;
    }
    return name;
  }, [container_statuses, name, showPodName]);

  const isStopped = useMemo(() => {
    if (container_statuses && container_statuses.length === 1) {
      const st = container_statuses[0];
      if (!st.started) {
        return true;
      }
      return false;
    }
    return false;
  }, [container_statuses]);

  if (isStopped && showStopped !== 'true') {
    return null;
  }

  // console.log('name', name, showSysPods)
  if (!showSysPods && HIDE_SYS_NAMESPACES.includes(ns)) {
    return null
  }

  return (
    <Row title={namedisplay}>
      <a
        target="_blank"
        href={lnk}
        style={style} rel="noreferrer"
      >
        <span>
          {/* {container_specs.length > 0 && container_specs[0].security_context__read_only_root_filesystem ? <>{' '}<span style={{ color: 'green' }}><CheckBoxIcon fontSize="small" /></span></> : <>{' '}<span style={{ color: 'red' }}><ReportIcon fontSize="small" /></span></>} */}
          {/* {icon} */}
          {' '}{/* prio}{' ' */}
          <MemoryLimitSpan limit={container_specs ? container_specs[0].requests__memory : null} />{' '}
          <MemoryLimitSpan limit={container_specs ? container_specs[0].limit__memory : null} />{' '}
          {container_specs.length !== 1 ? <>{' '}<span color={{ text: 'orange' }} title="Pods">{container_specs.length}</span></> : null}
          <LimitSpan limit={container_specs ? container_specs[0].requests__cpu : null} />
          <LimitSpan limit={container_specs ? container_specs[0].limit__cpu : null} />
        </span>
        <span titls={ns} style={{ color: 'gray', textOverflow: 'ellipsis' }}>{ns.replace('netsyno-', '')}</span>
        <span>{namedisplay}</span>
      </a>
      {showDateStarted ? (
        <div>Date started: {container_statuses && container_statuses[0]?.started_at}</div>
      ) : null}
      {showVolumes && volumes.length ? (
        <div style={{
          marginLeft: '20px',
          width: '100%',
          display: 'grid',
          gridTemplateColumns: '95px 30px 1fr',
          gridGap: '0 1rem',
        }}>
          {volumes.filter(v => v.claim_name).map(({ status__capacity__storage, spec__storage_class_name, claim_name }) => (
            <>
              <RenderStorageClass storageclass={spec__storage_class_name} />
              <div>{status__capacity__storage}</div>
              <div>{claim_name}</div>
            </>
          ))}
        </div>
      ) : null}
    </Row>
  );
}

function RenderStorageClass({ storageclass }) {
  const style = useMemo(() => {
    if (storageclass === 'local-path') {
      return { color: 'white', fontWeight: 'bold', background: 'red' }
    }
    if (storageclass === 'rook-ceph-block') {
      return { color: 'white', fontWeight: 'bold', background: 'green' }
    }
    if (storageclass === 'rook-cephfs') {
      return { color: 'white', fontWeight: 'bold', background: 'orange' }
    } return {}
  }, [storageclass])
  return <div style={style}>{storageclass}</div>
}

function MemoryLimitSpan({ limit }) {
  if (!limit) {
    return <span></span>
  }
  if (limit.includes("Gi")) {
    return <span style={{ color: 'red' }}>{limit}</span>
  }
  return <span style={{ color: 'blue' }}>{limit}</span>
}

function LimitSpan({ numb }) {
  if (!numb) {
    return <span></span>
  }
  if (numb > 1) {
    return <span style={{ color: 'red' }}>{numb}</span>
  }
  return <span style={{ color: 'blue' }}>{numb}</span>
}


function RenderPods({ pods }) {
  const { markRestartedPods, showStopped, showDateStarted, showVolumes, showSysPods, showPodName } = useQuery();

  const orderedPods = useMemo(() => {
    return pods.sort(sortPods);
  }, [pods]);

  return (
    <>
      <div style={{ maxWidth: '100%', fontSize: '0.7em' }}>
        {orderedPods.map((podc) => (
          <RenderOnePod
            key={`${podc.ns}__${podc.name}`}
            name={podc.name}
            ns={podc.ns}
            prio={podc.prio}
            container_statuses={podc.container_statuses}
            container_specs={podc.container_specs}
            volumes={podc.volumes}
            markRestartedPods={markRestartedPods}
            showPodName={showPodName}
            showStopped={showStopped}
            showDateStarted={showDateStarted}
            showVolumes={showVolumes}
            showSysPods={showSysPods}
          />
        ))}
      </div></>
  );
}

export default RenderPods;
