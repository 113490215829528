import React, { useContext, useMemo } from 'react';
import TokenContext from '../TokenContext';

import { Link } from 'react-router-dom';

export default function LinkWithToken({ pathname, ...rest }) {
  const token = useContext(TokenContext);
  const to = useMemo(() => ({ pathname, hash: `token=${token}` }), [pathname, token]);
  return <Link to={to} {...rest} />;
}
