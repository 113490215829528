import React, { useMemo, useCallback, useEffect } from 'react';
import useFetch from '../hooks/useFetch';
import { mutate } from 'swr';
import styled from 'styled-components';
import Home from '@mui/icons-material/Home';
import ReactMarkdown from 'react-markdown';
import TokenLoginForm from '../TokenLoginForm';
import LinkWithToken from '../components/LinkWithToken';

const Divpadded = styled.div`
  padding: 20px;
`;

const Wrapper = styled.div`
  max-width: 100%;
  margin-top: 30px;
`;

const InputWrapper = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  background: #f1f1f1;
  padding: 0px;
  align-items: center;
  color: #000;
  font-size: 14px;
  z-index: 1;
  width: 100%;
  > input {
    border: 1px solid #dadada;
    border-radius: 3px;
    height: 24px;
    font-size: 12px;
    padding: 0 8px;
  }
  & label {
    font-size: 13px;
  }
  > button {
    font-size: 13px;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 24px;
    padding: 0 12px;
    border: none;
    color: #0560f2;
    background: none;
    border-radius: 35px;
    > {
      svg {
        font-size: 17px !important;
        margin-right: 4px;
      }
    }
    :hover {
      background: rgba(5, 96, 242, 0.1);
    }
  }
`;

const HomeLinkHolder = styled(LinkWithToken)`
    padding 0 4px;
    color: #c0c0c0;
    :hover {
        color: #2a94cf;
    }
`;

function RenderLabel({ label }) {
  return (
    <span
      style={{
        marginRight: '8px',
        marginTop: '8px',
        padding: '4px',
        border: '1px solid #c0c0c0',
        background: 'pink',
      }}
    >
      {label}
    </span>
  );
}

function RenderOneIssue({ issue }) {
  const {
    title,
    labels,
    updated_at,
    description,
    has_conflicts,
    draft,
    author,
    iid,
    web_url,
    merge_requests_count,
    milestone,
    has_tasks,
    severity,
    user_notes_count,
  } = issue;
  console.log(issue);
  const { name: author_name } = author || {};

  const descriptionTruncated = useMemo(() => {
    if (!description) {
      return '';
    }
    return description.substring(0, 250);
  }, [description]);

  const updated_at_noms = useMemo(
    () => updated_at.substring(0, 19).split('T').join(' '),
    [updated_at]
  );

  const projectprefix = useMemo(() => web_url.split('/-/')[0], [web_url]); // 'https://gitlab.netsyno.com/netsyno/inopai', []);

  // console.log('projectprefix', projectprefix);

  const ImgComp = useCallback(
    ({ src, ...imgprops }) => {
      const srcFixed = `${projectprefix}${src}`;
      // eslint-disable-next-line jsx-a11y/alt-text
      return <img src={srcFixed} style={{ maxWidth: '200px' }} {...imgprops} />;
    },
    [projectprefix]
  );

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '380px 150px 1fr',
        gridGap: '1rem',
        border: '1px solid gray',
        marginBottom: '8px',
      }}
    >
      <div style={{ paddingLeft: '8px' }}>
        <strong>
          <a style={{ color: '#000' }} href={web_url} target="_blank" rel="noreferrer">
            {iid} {draft ? <span style={{ background: 'orange' }}>Draft </span> : null}
            {title}
          </a>
        </strong>
        <div>{author_name}</div>
        <div>
          {labels.map((label) => (
            <RenderLabel label={label} />
          ))}
        </div>
        <div>
          merge_requests_count {merge_requests_count}
          <br />
          milestone {milestone}
          <br />
          has_tasks {has_tasks}
          <br />
          severity {severity}
          <br />
          user_notes_count {user_notes_count}
        </div>
      </div>
      <div>
        {has_conflicts ? <div style={{ background: 'red' }}>Conflicts</div> : null}
        <div>{updated_at_noms}</div>
      </div>
      <div style={{ wordBreak: 'break-all' }}>
        <ReactMarkdown
          components={{
            img: ImgComp,
            // a: LinkWrap,
          }}
          transformLinkUri={null}
          uriTransformer={null}
          children={descriptionTruncated}
        />
      </div>
    </div>
  );
}

export default function InopaiissuesPage() {
  const { data, error } = useFetch('/api/get_inopai_issue_list');

  const reload = useCallback(() => {
    mutate('/api/get_inopai_issue_list');
  }, []);
  useEffect(() => {
    reload();
  }, [reload]);
  // console.log('data', data, error);

  if (error) {
    return <TokenLoginForm />;
  }
  if (!data) {
    return <Divpadded>Loading...</Divpadded>;
  }

  const filtereddata = data.filter((br) => br.name !== 'master');

  return (
    <>
      <InputWrapper>
        <HomeLinkHolder pathname="/">
          <Home />
        </HomeLinkHolder>
      </InputWrapper>
      <Wrapper style={{ padding: '16px' }}>
        {filtereddata.map((issue) => (
          <RenderOneIssue key={issue.iid} issue={issue} />
        ))}
      </Wrapper>
    </>
  );
}
